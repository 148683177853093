import React, { useEffect, useReducer, useState } from 'react';

import { useSnackbar } from 'notistack';

import { Dialog, DialogContent } from '@material-ui/core';

import { cloneDeep } from 'lodash';

import { useStyles } from './Themable.hooks';

import {
    useRegistersGroups,
    useRegistersMonitoredObjects,
} from '../../../../../../state/app/registers/index.hooks';
import { useRights } from '../../../../../../state/ui/usersSlice/index.hooks';
import {
    clearRights,
    fetchUserDataRights,
    IRightItem,
    patchRightsAssignments,
} from '../../../../../../state/ui/usersSlice';
import { IGroupItem } from '../../../../../../state/app/registers/groups';
import { IRegistersMonitoredObject } from '../../../../../../state/app/registers/monitoredObjects';

import { restGetGroups } from '../../../../../../services/groups';
import { restGetRegistersMonitoredObjects } from '../../../../../../services/registers';

import TranslationHelper from '../../../../../../helpers/TranslationHelper';
import { errorMessageHandler } from '../../../../../../helpers/errorMessageHandler';
import { sortAlphabetically } from '../../../../../../helpers/comparators';
import { monitoredObjectTypeConverter } from '../../../../../../helpers/monitoredObjectTypeConverter';

import { useToolkitDispatch } from '../../../../../../hooks';
import useAsyncThunkWithSnackbar from '../../../../../../hooks/useAsyncThunkWithSnackbar';

import { filterObjects } from './utils/filterObjects';

import DialogTitle from '../../../../../../components/dialogs/DialogTitle';
import LoadingSpinner from '../../../../../../components/loadingSpinner/LoadingSpinner';
import SectionWithTitle from '../../../../../../components/SectionWithTitle';
import RadioButtons from '../../../../../../components/RadioButtons';
import TransferList from '../../../../../../components/TransferList';
import {
    IFilterInitialState,
    IListObjectInitialState,
    ITransferListObject,
} from '../../../../../../components/TransferList/TransferList';
import {
    findIndexById,
    not,
    handleAddedAndRemoved,
} from '../../../../../../components/TransferList/utils/filterObjects';
import LeftList from './components/LeftList';
import RightList from './components/RightList';
import { prepareData } from './utils/prepareData';

interface IOwnProps {
    isOpen: boolean;
    closeDialog: () => void;
    userId: number;
}

const OPTIONS = ['ALL', 'SELECTED', 'NONE'];
const INITIAL_TYPE = { group: 'GROUP', all: 'ALL' };

const AssignRightsDialog = ({ isOpen, closeDialog, userId }: IOwnProps) => {
    const classes = useStyles();

    const { enqueueSnackbar } = useSnackbar();

    const toolkitDispatch = useToolkitDispatch();

    const monitoredObjects = useRegistersMonitoredObjects();

    const groups = useRegistersGroups();

    const rights = useRights();

    const [selectedRight, setSelectedRight] = useState('');

    const [added, setAdded] = useState<IRightItem[]>([]);
    const [removed, setRemoved] = useState<IRightItem[]>([]);

    const [loading, setLoading] = useState(false);

    const [checked, setChecked] = useState<IRightItem[]>([]);

    const handleAsyncRequest = useAsyncThunkWithSnackbar();

    const filterInitialState: IFilterInitialState = {
        typeUnassigned: INITIAL_TYPE.group,
        nameUnassigned: '',
        typeAssigned: INITIAL_TYPE.all,
        nameAssigned: '',
    };

    const listObjectsInitialState: IListObjectInitialState<ITransferListObject> =
        {
            unassignedObjects: [],
            assignedObjects: [],
            filteredAssignedObjects: [],
            originalAssignedObjects: [],
        };

    const [filter, setFilter] = useReducer(
        (
            curVals: IFilterInitialState,
            newVals: Partial<IFilterInitialState>
        ) => ({ ...curVals, ...newVals }),
        filterInitialState
    );

    const [objectLists, setObjectLists] = useReducer(
        (
            curVals: IListObjectInitialState<ITransferListObject>,
            newVals: Partial<IListObjectInitialState<ITransferListObject>>
        ) => ({
            ...curVals,
            ...newVals,
        }),
        listObjectsInitialState
    );

    useEffect(() => {
        if (filter.typeUnassigned === INITIAL_TYPE.group) {
            setObjectLists({
                unassignedObjects: filterObjects(
                    handleAddedAndRemoved(
                        not(groups || [], objectLists.originalAssignedObjects),
                        added,
                        removed
                    ),
                    filter.typeUnassigned,
                    filter.nameUnassigned
                ),
            });
            setLoading(false);
        }
    }, [groups, objectLists.originalAssignedObjects]);

    useEffect(() => {
        if (filter.typeUnassigned === 'MONITORED_OBJECT') {
            setObjectLists({
                unassignedObjects: filterObjects(
                    handleAddedAndRemoved(
                        not(
                            monitoredObjects,
                            objectLists.originalAssignedObjects
                        ),
                        added,
                        removed
                    ),
                    filter.typeUnassigned,
                    filter.nameUnassigned
                ),
            });
        }
    }, [monitoredObjects, objectLists.originalAssignedObjects]);

    useEffect(() => {
        setLoading(true);
        handleAsyncRequest({
            asyncAction: fetchUserDataRights(userId),
            onSuccess: { callback: () => setLoading(false) },
            onError: {
                callback: () => setLoading(false),
            },
        });
    }, [userId]);

    useEffect(() => {
        if (rights.length === 0) {
            setSelectedRight('NONE');
            return;
        } else if (rights.some((right) => right.relationId === 'ALL')) {
            setSelectedRight('ALL');
            return;
        }
        setLoading(true);
        setSelectedRight('SELECTED');
        Promise.all([
            restGetGroups({
                individualForUserId: userId,
            }),
            restGetRegistersMonitoredObjects({
                individualForUserId: userId,
            }),
        ]).then((values) => {
            const assignments: ITransferListObject[] = [];

            const assignedGroups = prepareAssignedGroups(
                values[0] as IGroupItem[]
            );
            const assignedObjects = prepareAssignedObjects(values[1]);
            const specialRights = prepareSpecialRights(rights as IRightItem[]);

            assignments.push(
                ...assignedObjects,
                ...assignedGroups,
                ...specialRights
            );

            const sortedAssignments = assignments.sort((a, b) =>
                sortAlphabetically(a.name, b.name)
            );
            setObjectLists({
                originalAssignedObjects: sortedAssignments,
                filteredAssignedObjects:
                    filter.typeAssigned === INITIAL_TYPE.all
                        ? sortedAssignments
                        : sortedAssignments.filter(
                              (assignment) =>
                                  assignment.type === INITIAL_TYPE.group
                          ),
                assignedObjects: sortedAssignments,
            });
        });
    }, [rights, userId]);

    const showNotification = (success: boolean, message: string) => {
        enqueueSnackbar(message, {
            variant: success ? 'success' : 'error',
        });
    };
    const handleSubmit = () => {
        if (selectedRight === 'SELECTED' && !added.length && !removed.length) {
            handleCloseDialog();
            return;
        }
        setLoading(true);
        toolkitDispatch(
            patchRightsAssignments({
                id: userId,
                data: {
                    mode: selectedRight,
                    added: prepareData(added),
                    removed: prepareData(removed),
                },
            })
        )
            .unwrap()
            .then(() => {
                showNotification(
                    true,
                    TranslationHelper.translate('Changes have been saved')
                );
                setLoading(false);
                handleCloseDialog();
            })
            .catch((error) => {
                const message = errorMessageHandler(error.status)();
                showNotification(false, message);
                setLoading(false);
                handleCloseDialog();
            });
    };

    const handleCloseDialog = (event?: object, reason?: string) => {
        if (reason === 'backdropClick') {
            return;
        }
        toolkitDispatch(clearRights());
        closeDialog();
    };

    const handleChangeRight = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedRight(e.target.value);
    };

    const handleToggle = (value: IRightItem) => () => {
        const currentIndex = findIndexById(checked, value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
    };

    const prepareAssignedGroups = (arr: IGroupItem[]) => {
        const assignedGroups = [...arr].map((group) => {
            const rightObject = {} as IRightItem;
            rightObject.name = group.name;
            rightObject.relationId = group.id;
            rightObject.type = 'GROUP';
            return rightObject;
        });
        return assignedGroups;
    };
    const prepareAssignedObjects = (arr: IRegistersMonitoredObject[]) => {
        const assignedObjects = [...arr].map((object) => {
            const rightObject = {} as IRightItem;
            rightObject.name = object.name;
            rightObject.relationId = String(object.id);
            rightObject.registerType = monitoredObjectTypeConverter(
                object.type
            );
            rightObject.type = 'MONITORED_OBJECT';
            rightObject.registerId = object.objectId;
            return rightObject;
        });

        return assignedObjects;
    };

    const prepareSpecialRights = (arr: IRightItem[]) => {
        const specialRights = cloneDeep(arr).filter(
            (right) => right.type === 'SPECIAL'
        );
        specialRights.forEach(
            (right) =>
                (right.name = TranslationHelper.translate(
                    String(right.relationId)
                ))
        );
        return specialRights;
    };
    const leftList = (
        <LeftList
            objects={objectLists}
            setObjects={setObjectLists}
            checked={checked}
            setChecked={setChecked}
            filter={filter}
            setFilter={setFilter}
            handleToggle={handleToggle}
        />
    );

    const rightList = (
        <RightList
            objects={objectLists}
            setObjects={setObjectLists}
            checked={checked}
            setChecked={setChecked}
            filter={filter}
            setFilter={setFilter}
            handleToggle={handleToggle}
        />
    );

    const renderTransferList = () => {
        return selectedRight === 'SELECTED' ? (
            <TransferList
                objectLists={objectLists}
                setObjectLists={setObjectLists}
                added={added}
                setAdded={setAdded}
                removed={removed}
                setRemoved={setRemoved}
                filter={filter}
                customComponents={{
                    leftList,
                    rightList,
                }}
                checked={checked}
                setChecked={setChecked}
                filterObjects={filterObjects}
            />
        ) : null;
    };
    return (
        <>
            <Dialog
                open={isOpen}
                keepMounted={true}
                onClose={handleCloseDialog}
                maxWidth={'lg'}
                classes={{ paper: classes.paper }}
            >
                <DialogTitle
                    title={`${TranslationHelper.translate(
                        'Data access rights'
                    )}`}
                    close={handleCloseDialog}
                    save={handleSubmit}
                    loading={loading}
                ></DialogTitle>
                <DialogContent>
                    <div className={classes.wrapper}>
                        {loading ? (
                            <LoadingSpinner
                                size="50"
                                top="0"
                                left="0"
                                right="0"
                                bottom="0"
                            />
                        ) : (
                            <>
                                <SectionWithTitle title="Rights">
                                    <RadioButtons
                                        options={OPTIONS}
                                        value={selectedRight}
                                        handleChange={handleChangeRight}
                                    />
                                </SectionWithTitle>
                                {renderTransferList()}
                            </>
                        )}
                    </div>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default AssignRightsDialog;
