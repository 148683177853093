import React from 'react';

import { FormikErrors, FormikValues } from 'formik';

import { MenuItem } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

import {
    IEmployee,
    updateEmployee,
} from '../../../../../../state/app/registers/employees';
import {
    ITank,
    updateFuelTank,
} from '../../../../../../state/app/registers/tanks';
import {
    IVehicle,
    updateVehicle,
} from '../../../../../../state/app/registers/vehicles';
import {
    restGetEmployees,
    restGetTanks,
    restGetVehicles,
} from '../../../../../../services/registers';

import TranslationHelper from '../../../../../../helpers/TranslationHelper';

import {
    employeeEditSchema,
    tankEditSchema,
    vehicleEditSchema,
} from '../../../../../../schemas';

import {
    STANDARD_LOGIC,
    REVERSED_LOGIC,
} from '../../../../../../constants/dictionaries/TankLogic';

import FieldWrapper from '../../../../../../components/common/formikWrappers/FieldWrapper';
import { IGroup } from 'src/app/state/app/collections/dataTypes';

export type TFormData = IVehicle | IEmployee | ITank;

const getCommonProps = (
    name: string,
    errors: FormikErrors<TFormData>,
    classes: ClassNameMap<'field' | 'paneContent'>
) => {
    const path = name.split('.');
    let error: FormikErrors<TFormData> | string = errors;
    path.forEach((el) => {
        if (error && error[el]) {
            error = error[el];
        } else {
            error = '';
        }
    });

    return {
        name,
        key: name,
        error: !!error,
        helperText: error,
        fullWidth: true,
        component: FieldWrapper,
        className: classes.field,
    };
};

export const vehicleFields = (
    errors: FormikErrors<TFormData>,
    classes: ClassNameMap<'field' | 'paneContent'>
) => [
    {
        ...getCommonProps('name', errors, classes),
        required: true,
        label: TranslationHelper.translate('Object name'),
    },
    {
        ...getCommonProps('timeZone', errors, classes),
        type: 'tz',
    },
    {
        ...getCommonProps('groups', errors, classes),
        required: true,
        type: 'groups',
    },
    {
        ...getCommonProps('plateNumber', errors, classes),
        label: TranslationHelper.translate('Vehicle plate number'),
    },
    {
        ...getCommonProps('sideNumber', errors, classes),
        label: TranslationHelper.translate('Vehicle side number'),
    },
    {
        ...getCommonProps('mark', errors, classes),
        label: TranslationHelper.translate('Vehicle mark'),
    },
    {
        ...getCommonProps('model', errors, classes),
        label: TranslationHelper.translate('Vehicle model'),
    },
    {
        ...getCommonProps('vin', errors, classes),
        label: TranslationHelper.translate('VIN'),
    },
    {
        ...getCommonProps('productionYear', errors, classes),
        type: 'number',
        label: TranslationHelper.translate('Vehicle production year'),
    },
    {
        ...getCommonProps('fuelConsumption', errors, classes),
        type: 'number',
        label: TranslationHelper.translate('Vehicle fuel consumption'),
    },
    {
        ...getCommonProps('fuelConsumptionType', errors, classes),
        label: '',
        select: true,
        children: [
            <MenuItem value={'km'} key={'fuelConsumptionKm'}>
                l/100km
            </MenuItem>,
            <MenuItem value={'mth'} key={'fuelConsumptionMth'}>
                l/h (l/mth)
            </MenuItem>,
        ],
    },
    {
        ...getCommonProps('tankSize', errors, classes),
        type: 'number',
        label: TranslationHelper.translate('Vehicle fuel capacity'),
    },
];

export const employeeFields = (
    errors: FormikErrors<TFormData>,
    classes: ClassNameMap<'field' | 'paneContent'>
) => [
    {
        ...getCommonProps('name', errors, classes),
        required: true,
        label: TranslationHelper.translate('Object name'),
    },
    {
        ...getCommonProps('timeZone', errors, classes),
        type: 'tz',
    },
    {
        ...getCommonProps('groups', errors, classes),
        required: true,
        type: 'groups',
    },
    {
        ...getCommonProps('firstName', errors, classes),
        required: true,
        label: TranslationHelper.translate('First name'),
    },
    {
        ...getCommonProps('surname', errors, classes),
        required: true,
        label: TranslationHelper.translate('Surname'),
    },
    {
        ...getCommonProps('contact.email', errors, classes),
        label: TranslationHelper.translate('Email'),
    },
    {
        ...getCommonProps('contact.phoneNumber', errors, classes),
        label: TranslationHelper.translate('Phone'),
    },
    {
        ...getCommonProps('contact.mobileNumber', errors, classes),
        label: TranslationHelper.translate('Mobile'),
    },
    {
        ...getCommonProps('address.street', errors, classes),
        label: TranslationHelper.translate('Street'),
    },
    {
        ...getCommonProps('address.houseNumber', errors, classes),
        label: TranslationHelper.translate('House number'),
    },
    {
        ...getCommonProps('address.apartmentNumber', errors, classes),
        label: TranslationHelper.translate('Flat number'),
    },
    {
        ...getCommonProps('address.postalCode', errors, classes),
        label: TranslationHelper.translate('Postal code'),
    },
    {
        ...getCommonProps('address.city', errors, classes),
        label: TranslationHelper.translate('City'),
    },
];

export const tankFields = (
    errors: FormikErrors<TFormData>,
    classes: ClassNameMap<'field' | 'paneContent'>
) => [
    {
        ...getCommonProps('name', errors, classes),
        required: true,
        label: TranslationHelper.translate('Object name'),
    },
    {
        ...getCommonProps('timeZone', errors, classes),

        type: 'tz',
    },
    {
        ...getCommonProps('groups', errors, classes),
        type: 'groups',
        required: false,
    },
    {
        ...getCommonProps('capacity', errors, classes),
        type: 'number',
        label: TranslationHelper.translate('Capacity'),
    },
    {
        ...getCommonProps('reserve', errors, classes),
        type: 'number',
        label: TranslationHelper.translate('FuelTank_Reserve'),
    },
    {
        ...getCommonProps('logicType', errors, classes),
        label: TranslationHelper.translate('Logic'),
        select: true,
        children: [
            <MenuItem value={STANDARD_LOGIC} key={'logicType' + STANDARD_LOGIC}>
                {TranslationHelper.translate('Simple')}
            </MenuItem>,
            <MenuItem value={REVERSED_LOGIC} key={'logicType' + REVERSED_LOGIC}>
                {TranslationHelper.translate('Reversed')}
            </MenuItem>,
        ],
    },
    {
        ...getCommonProps('fuelType', errors, classes),
        label: TranslationHelper.translate('Content'),
    },
    {
        ...getCommonProps('description', errors, classes),
        label: TranslationHelper.translate('Description'),
    },
];

export const vehicleInitialValues = {
    name: '',
    timeZone: '',
    group: '',
    plateNumber: '',
    sideNumber: '',
    mark: '',
    model: '',
    vin: '',
    productionYear: '',
    fuelConsumption: '',
    fuelConsumptionWork: '',
    tankSize: '',
};

export const employeeInitialValues = {
    name: '',
    timeZone: '',
    group: '',
    firstName: '',
    surname: '',
    email: '',
    phoneNumber: '',
    mobileNumber: '',
    street: '',
    houseNumber: '',
    apartmentNumber: '',
    postalCode: '',
    city: '',
};

export const tankInitialValues = {
    name: '',
    timeZone: '',
    capacity: '',
    reserve: '',
    logicType: '',
    fuelType: '',
    description: '',
};

export const objectTypes = {
    TANK: 14,
    VEHICLE: 8,
    EMPLOYEE: 3,
};

export const schemas = {
    [objectTypes.VEHICLE]: vehicleEditSchema,
    [objectTypes.EMPLOYEE]: employeeEditSchema,
    [objectTypes.TANK]: tankEditSchema,
};

export const fields = {
    [objectTypes.VEHICLE]: vehicleFields,
    [objectTypes.EMPLOYEE]: employeeFields,
    [objectTypes.TANK]: tankFields,
};

export const initialValues = {
    [objectTypes.VEHICLE]: vehicleInitialValues,
    [objectTypes.EMPLOYEE]: employeeInitialValues,
    [objectTypes.TANK]: tankInitialValues,
};

export const dataFetch = {
    [objectTypes.VEHICLE]: (id: number) =>
        restGetVehicles({ name: '', limit: 1000, externalId: id }).then(
            ({ values }) => {
                const data = values.filter(
                    (value) => value.externalId === id
                )[0];
                const { fuelConsumptionWork, fuelConsumption } = data;
                return {
                    ...data,
                    fuelConsumptionType: fuelConsumptionWork ? 'mth' : 'km',
                    fuelConsumption:
                        fuelConsumptionWork || fuelConsumption || 0,
                };
            }
        ),
    [objectTypes.EMPLOYEE]: (id: number) =>
        restGetEmployees({ name: '', limit: 1000, externalId: id }).then(
            ({ values }) => values[0]
        ),
    [objectTypes.TANK]: (id: number) =>
        restGetTanks({ name: '', limit: 1000, externalId: id }).then(
            ({ values }) => values[0]
        ),
};

export const prepareData = {
    [objectTypes.VEHICLE]: (values: FormikValues) => {
        const data = {
            ...values,
        };

        if (values.fuelConsumptionType === 'mth') {
            data.fuelConsumptionWork = values.fuelConsumption;
            data.fuelConsumption = 0;
        } else {
            data.fuelConsumption = values.fuelConsumption;
            data.fuelConsumptionWork = 0;
        }
        data.groupIds = data.groups.map((group: IGroup) => group.id);
        return data;
    },
    [objectTypes.EMPLOYEE]: (values: FormikValues) => {
        const data = {
            ...values,
        };

        data.groupIds = data.groups.map((group: IGroup) => group.id);

        return data;
    },
    [objectTypes.TANK]: (values: FormikValues) => {
        const data = { ...values };
        data.groupIds = data.groups
            ? data.groups.map((group: IGroup) => group.id)
            : [];
        return data;
    },
};

export const saveData = {
    [objectTypes.VEHICLE]: updateVehicle,
    [objectTypes.EMPLOYEE]: updateEmployee,
    [objectTypes.TANK]: updateFuelTank,
};
