import React, {
    Dispatch,
    MouseEventHandler,
    SetStateAction,
    useEffect,
} from 'react';

import {
    useRegistersGroupsLoading,
    useRegistersMonitoredObjectsLoading,
} from '../../../../../../../../state/app/registers/index.hooks';
import { fetchRegistersGroups } from '../../../../../../../../state/app/registers/groups';
import { fetchRegistersMonitoredObjects } from '../../../../../../../../state/app/registers/monitoredObjects';
import { IRightItem } from '../../../../../../../../state/ui/usersSlice';

import { SPECIAL_DATA_RIGHTS } from '../../../../../../../../constants/dictionaries/SpecialDataRights';
import { DEBOUNCE_LOW } from '../../../../../../../../constants/DebounceValues';

import TranslationHelper from '../../../../../../../../helpers/TranslationHelper';
import { useDebounceEffect } from '../../../../../../../../helpers/hooks';

import { useToolkitDispatch } from '../../../../../../../../hooks';

import CustomList from '../../../../../../../../components/TransferList/components/CustomList';
import { not } from '../../../../../../../../components/TransferList/utils/filterObjects';
import {
    IFilterInitialState,
    IListObjectInitialState,
} from '../../../../../../../../components/TransferList/TransferList';
import { lowerCaseFilter } from '../../utils/filterObjects';

const TYPES = ['GROUP', 'MONITORED_OBJECT', 'SPECIAL'];

interface IOwnProps {
    objects: IListObjectInitialState<IRightItem>;
    setObjects: React.Dispatch<Partial<IListObjectInitialState<IRightItem>>>;
    checked: IRightItem[];
    setChecked: Dispatch<SetStateAction<IRightItem[]>>;
    filter: IFilterInitialState;
    setFilter: React.Dispatch<Partial<IFilterInitialState>>;
    handleToggle: (value: IRightItem) => MouseEventHandler | undefined;
    disabled?: boolean;
}

const LeftList = ({
    filter,
    setFilter,
    objects,
    setObjects,
    checked,
    setChecked,
    handleToggle,
    disabled,
}: IOwnProps) => {
    const toolkitDispatch = useToolkitDispatch();

    const grupsLoading = useRegistersGroupsLoading();
    const objectsLoading = useRegistersMonitoredObjectsLoading();

    const { nameUnassigned, typeUnassigned } = filter;
    const { unassignedObjects, assignedObjects } = objects;

    const specialRights = SPECIAL_DATA_RIGHTS.map((right) => {
        right.name = TranslationHelper.translate(right.name);
        return right;
    });

    useDebounceEffect(
        () => {
            handleChangeNameUnassigned();
        },
        [nameUnassigned],
        DEBOUNCE_LOW
    );

    useEffect(() => {
        handleChangeTypeUnassigned(typeUnassigned);
    }, [typeUnassigned]);

    const unassignedTitle = TranslationHelper.translate('objects.unassigned');

    const handleChangeTypeUnassigned = (type: string) => {
        setFilter({ typeUnassigned: type });
        handleFetch(type);
    };

    const handleFetch = (type: string) => {
        switch (type) {
            case 'GROUP':
                toolkitDispatch(fetchRegistersGroups({ name: nameUnassigned }));
                break;
            case 'MONITORED_OBJECT':
                toolkitDispatch(
                    fetchRegistersMonitoredObjects({ name: nameUnassigned })
                );
                break;
            case 'SPECIAL':
                setObjects({
                    unassignedObjects: not(
                        specialRights.filter((right) =>
                            lowerCaseFilter(right, nameUnassigned)
                        ),
                        assignedObjects
                    ),
                });
        }
    };
    const handleChangeNameUnassigned = () => {
        handleFetch(typeUnassigned);
    };

    return (
        <CustomList
            loaded={!grupsLoading && !objectsLoading}
            typeInput={{
                type: typeUnassigned,
                setType: (type) => setFilter({ typeUnassigned: type }),
            }}
            nameInput={{
                name: nameUnassigned,
                setName: (name) => setFilter({ nameUnassigned: name }),
            }}
            title={unassignedTitle}
            items={unassignedObjects}
            handleToggle={handleToggle}
            checked={checked}
            setChecked={setChecked}
            types={TYPES}
            disabled={disabled}
        />
    );
};

export default LeftList;
