import URI from 'urijs';
import AppConfig from '../../constants/AppConfig';
import UrlConst from '../../constants/UrlConst';
import {
    IGroup,
    IGroupWithQuantity,
    INormalizedData,
} from '../../state/app/collections/dataTypes';
import apiClient from '../common/apiClient';
import {
    makeGetRequestSettings,
    makePostRequestSettings,
    makePutRequestSettings,
    makePatchRequestSettings,
    makeUrl,
} from '../common/utils';
import { IGroupAssignment } from '../../state/ui/groupsSlice';

export interface IGetGroupEmptyAssignmentsParams {
    name: string;
    type?: string;
    tags?: string;
    excludedGroupId?: string;
}

export interface IGetLocationGroupEmptyAssignmentsParams {
    name: undefined;
    type?: string;
    tags?: string;
    excludedGroupId?: string;
    search?: string;
}

export interface IGetGroupsParams {
    name?: string;
    individualForUserId?: number;
}

export type TAssignmentRegisterType =
    | 'VEHICLE'
    | 'EMPLOYEE'
    | 'TANK'
    | 'RFID_CARD';

export const makeGroupsUrl = (suffix: string, params = {}) => {
    const url = new URI(
        AppConfig.instance.getUrl(UrlConst.GROUPS_ENDPOINT_URL) + suffix
    );
    return makeUrl(url, params);
};

export const makeDevicesUrl = (suffix: string = '', params = {}) => {
    const url = new URI(
        AppConfig.instance.getUrl(UrlConst.REGISTERS_DEVICES_URL) + suffix
    );
    return makeUrl(url, params);
};

export const restGetGroups = (params?: IGetGroupsParams): Promise<IGroup[]> =>
    apiClient.sendWithoutErrorsHandling(
        makeGetRequestSettings(makeGroupsUrl(``, params))
    );

export const restGetGroupsWithQuantity = (
    registerType: TAssignmentRegisterType
): Promise<IGroupWithQuantity[]> =>
    apiClient.sendWithoutErrorsHandling(
        makeGetRequestSettings(makeGroupsUrl(`/quantity`, { registerType }))
    );

export interface IRestPostGroup {
    name: string;
    number?: number;
}

export const restPostGroup = (group: IRestPostGroup): Promise<null> =>
    apiClient.sendWithoutErrorsHandling(
        makePostRequestSettings(makeGroupsUrl(``), group)
    );

export interface IRestPutGroup {
    id: string;
    name: string;
    number: number;
}

export const restPutGroup = (group: IRestPutGroup): Promise<null> =>
    apiClient.sendWithoutErrorsHandling(
        makePutRequestSettings(makeGroupsUrl(`/${group.id}`), group)
    );

export const restGetGroupAssignments = (
    id: string
): Promise<IGroupAssignment[]> =>
    apiClient.sendWithoutErrorsHandling(
        makeGetRequestSettings(makeGroupsUrl(`/${id}/assignments`))
    );

export const restGetGroupEmptyAssignments = (
    queryParams?: IGetGroupEmptyAssignmentsParams
): Promise<IGroupAssignment[]> =>
    apiClient.sendWithoutErrorsHandling(
        makeGetRequestSettings(makeGroupsUrl(`/assignments`, queryParams))
    );

export interface IGroupAssignmentModification {
    registerId: number;
    type: string;
    relationId: string;
}

export interface IRestPatchGroupAssignments {
    added: IGroupAssignment[];
    removed: IGroupAssignment[];
}

export const restPatchGroupAssignments = (
    id: string,
    assignments: IRestPatchGroupAssignments
): Promise<null> =>
    apiClient.sendWithoutErrorsHandling(
        makePatchRequestSettings(
            makeGroupsUrl(`/${id}/assignments`),
            assignments
        )
    );

interface IGroupDataEntry {
    id: string;
    name: string;
    number: number;
}
const normalizeGroupCollection = <T extends IGroupDataEntry>(
    data: IGroupDataEntry[]
): INormalizedData<T> => {
    return {
        result: data.map(({ id }) => id.toString()),
        entities: data.reduce((result, next) => {
            result[next.id] = next;
            return result;
        }, {}),
    };
};
export const makeRestReadGroupCollection =
    <T extends IGroupDataEntry>(
        restGetCollection: () => Promise<T[]>
    ): (() => Promise<INormalizedData<T>>) =>
    () =>
        restGetCollection().then((data) => normalizeGroupCollection<T>(data));

export const restReadGroupsCollection =
    makeRestReadGroupCollection(restGetGroups);
