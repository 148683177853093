import { makeReduxDuck } from 'teedux';
import { simulateProperDataForPresentation } from '../../../../services/discovery/_shared/utils';

import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { IBookmark, sourceSetIds } from '../../../types';
import { TFormTypes } from '../../forms';
import {
    IPreviewAction,
    IReportSetParams,
    ISourceSetAction,
    TDialogAction,
} from '../types';
import { IPlayerSettings } from '../../discoverySlice';

export type TComponentType =
    | 'frameDetails'
    | 'aggregationDetails'
    | 'calibrationSetting'
    | 'alertSetting'
    | 'registerRefueling'
    | 'devices'
    | 'refuelingAndFuelLossDetectionSettings';

export type TSnapshotType =
    | 'category'
    | 'preview'
    | 'location'
    | 'report-set'
    | 'source-set-grid'
    | 'source-set-grid-chart'
    | 'source-set-chart'
    | 'source-set-element'
    | 'source-set-element-movieplayer'
    | TComponentType;

export type TAction = { type: 'showEvent'; date?: string };

export type TPreviewPane = null | {
    elementCollectionName: string;
    elementId: string | null;
    reportSetParams?: IReportSetParams;
    dataSegmentsReports?: IBookmark[];
    previewAction: null | IPreviewAction;
    creatorLevel: number;
    elementType: string;
    mode: string;
    storeResult?: TFormTypes;
    actionsToTrigger?: TAction[];
};

export type TDataGridPane = {
    sourceSetId: string;
    sourceSetAction: null | ISourceSetAction;
    preview: TPreviewPane | null;
    creatorLevel: number;
    isHidden: boolean;
    isChart: boolean;
    isFullscreen: boolean;
    isAnalyticsEnabled: boolean;
    visibleOnMobile: boolean;
    mobileScrollOffset: number;
    filterValue?: string;
    sourceSetDates?: {
        from: string;
        to: string;
    };
    anonymizeImages?: boolean;
    time?: {
        realizationTimeFrom: string;
        realizationTimeTo: string;
    };
    onlyNotAssigned?: boolean;
    onlyNotPlanned?: boolean;
    includeNotPlanned?: boolean;
    hiddenParams?: boolean;
    filters?: { [key: string]: string | number | boolean };
};

export type TChartPane = null | {
    params: {
        allEvents: string;
        filteredEvents: string;
    };
    creatorLevel: number;
};

export interface ICalibrationPane {
    activeAttributes: { [key: string]: boolean };
    type: 'time' | 'distance';
    creatorLevel?: number;
}

export interface IDetectionPane {
    activeAttributes: { [key: string]: boolean };
    creatorLevel?: number;
}

export type TDialog = {
    elementCollectionName: string;
    elementId: string | null;
    reportSetParams?: IReportSetParams;
    dialogAction: null | TDialogAction;
    creatorLevel: number;
    elementType: string;
    mode: string;
    storeResult?: TFormTypes;
    timestamp?: number;
    sliderTimeRange?: number[];
    anonymize?: boolean;
    playerSettings?: IPlayerSettings;
};

export interface ISnapshot {
    type: TSnapshotType;
    previewPane?: TPreviewPane;
    dataGridPane?: TDataGridPane | null;
    chartPane?: TChartPane;
    dialog?: TDialog;
    calibrationPane?: ICalibrationPane | null;
    detectionPane?: IDetectionPane | null;
    selectedId?: string | null;
    clustering?: boolean;
    actionsToTrigger?: TAction[];
}

export interface ICurrentSnapshot {
    pathIndex: number;
    level: number;
}

export interface IMonitoringRestorableData {
    currentSnapshot: {
        pathIndex: number;
        level: number;
    };
    snapshotPaths: ISnapshot[][];
    timeLocked: boolean;
}

interface IActivateActionParams {
    elementCollectionName?: string;
    elementId: string | null;
    elementType: string;
    mode: string;
    snapshot: {
        type: TSnapshotType;
        level: number;
        storeResult?: TFormTypes;
        timestamp?: number;
        sliderTimeRange?: number[];
        anonymize?: boolean;
        playerSettings?: IPlayerSettings;
    };
    actionType: string;
    previewAction?: TDialogAction;
    blocked?: boolean;
    fitMap?: boolean;
    resetReports?: boolean;
    closeGrid?: boolean;
    actionsToTrigger?: TAction[];
}

export interface IActivatePreviewParams {
    elementCollectionName: string;
    elementId: string | null;
    elementType: string;
    mode: string;
    snapshot: { type: TSnapshotType; level: number; storeResult?: TFormTypes };
    previewAction?: IPreviewAction;
    blocked?: boolean;
    fitMap?: boolean;
    resetReports?: boolean;
    closeGrid?: boolean;
    actionsToTrigger?: TAction[];
}

export interface IActivateGridParams {
    sourceSetId: string;
    snapshot: { level: number; type: TSnapshotType };
    preview?: TPreviewPane | null;
    isHidden?: boolean;
    action?: ISourceSetAction;
    blocked?: boolean;
    isFullscreen?: boolean;
    isAnalyticsEnabled?: boolean;
    visibleOnMobile?: boolean;
    mobileScrollOffset?: number;
    resetAttributes?: boolean;
    filters?: { [key: string]: string | number | boolean };
    actionsToTrigger?: TAction[];
    anonymizeImages?: boolean;
}
interface IState {
    initialSnapshot: ISnapshot;
    currentSnapshot: ICurrentSnapshot;
    activeChartAttributes: {
        [key: string]: boolean;
    };
    snapshotPaths: ISnapshot[][];
    mapFitToExtentIsPending: boolean;
    timeLocked: boolean;
    expandedReports: {
        [key: string]: boolean;
    };
    currentSnapshotToRestore?: ICurrentSnapshot;
    isStateRestoring: boolean;
    shouldRestorerWait: boolean;
}

const initialState: IState = {
    initialSnapshot: {
        type: 'category',
        previewPane: null,

        dataGridPane: {
            sourceSetId: sourceSetIds.lastStates,
            sourceSetAction: null,
            creatorLevel: -1,
            preview: null,
            isHidden: true,
            isChart: false,
            isFullscreen: false,
            isAnalyticsEnabled: false,
            visibleOnMobile: false,
            mobileScrollOffset: 0,
        },
        chartPane: null,
    },
    activeChartAttributes: {},
    currentSnapshot: {
        pathIndex: 0,
        level: 0,
    },
    snapshotPaths: [],
    mapFitToExtentIsPending: true,
    timeLocked: false,
    expandedReports: {},
    currentSnapshotToRestore: undefined,
    isStateRestoring: false,
    shouldRestorerWait: false,
};

const updateGridWithValue = (
    snapshotPaths: ISnapshot[][],
    pathIndex: number,
    sourceSetId: string,
    keyToUpdate: string,
    value: any,
    mode: 'toggle' | 'insert' = 'insert'
): { snapshotPaths: ISnapshot[][] } => {
    const snapshotPath = snapshotPaths[pathIndex];
    let gridSnapshotIndex: number | undefined;

    for (let i = snapshotPath.length - 1; i >= 0; i--) {
        if (snapshotPath[i].dataGridPane?.sourceSetId === sourceSetId) {
            gridSnapshotIndex = i;
            break;
        }
    }

    return {
        snapshotPaths: snapshotPaths.map((path, index) =>
            index === pathIndex
                ? path.map((snapshot, snapShotIndex) =>
                      snapShotIndex === gridSnapshotIndex
                          ? {
                                ...snapshot,
                                dataGridPane: {
                                    ...snapshot.dataGridPane,
                                    [keyToUpdate]:
                                        mode === 'toggle'
                                            ? !snapshot.dataGridPane?.[
                                                  keyToUpdate
                                              ]
                                            : value,
                                } as TDataGridPane,
                            }
                          : snapshot
                  )
                : path
        ),
    };
};

const updateSnapshotWithValue = (
    snapshotPaths: ISnapshot[][],
    pathIndex: number,
    level: number,
    keyToUpdate: string,
    value: any
): { snapshotPaths: ISnapshot[][] } => {
    return {
        snapshotPaths: snapshotPaths.map((path, index) =>
            index === pathIndex
                ? path.map((snapshot, levelIndex) => {
                      if (level !== levelIndex) {
                          return snapshot;
                      }
                      return {
                          ...snapshot,
                          [keyToUpdate]: value,
                      };
                  })
                : path
        ),
    };
};

const updateCalibrationWithValue = (
    snapshotPaths: ISnapshot[][],
    pathIndex: number,
    keyToUpdate: string,
    value: any
): { snapshotPaths: ISnapshot[][] } => {
    const snapshotPath = snapshotPaths[pathIndex];
    let snapshotIndex: number | undefined;

    for (let i = snapshotPath.length - 1; i >= 0; i--) {
        if (snapshotPath[i].type === 'calibrationSetting') {
            snapshotIndex = i;
            break;
        }
    }

    return {
        snapshotPaths: snapshotPaths.map((path, index) =>
            index === pathIndex
                ? path.map((snapshot, snapShotIndex) =>
                      snapShotIndex === snapshotIndex
                          ? {
                                ...snapshot,
                                calibrationPane: {
                                    ...snapshot.calibrationPane,
                                    [keyToUpdate]: value,
                                } as ICalibrationPane,
                            }
                          : snapshot
                  )
                : path
        ),
    };
};

const updateDetectionWithValue = (
    snapshotPaths: ISnapshot[][],
    pathIndex: number,
    keyToUpdate: string,
    value: any
): { snapshotPaths: ISnapshot[][] } => {
    const snapshotPath = snapshotPaths[pathIndex];
    let snapshotIndex: number | undefined;

    for (let i = snapshotPath.length - 1; i >= 0; i--) {
        if (snapshotPath[i].type === 'refuelingAndFuelLossDetectionSettings') {
            snapshotIndex = i;
            break;
        }
    }

    return {
        snapshotPaths: snapshotPaths.map((path, index) =>
            index === pathIndex
                ? path.map((snapshot, snapShotIndex) =>
                      snapShotIndex === snapshotIndex
                          ? {
                                ...snapshot,
                                detectionPane: {
                                    ...snapshot.detectionPane,
                                    [keyToUpdate]: value,
                                } as IDetectionPane,
                            }
                          : snapshot
                  )
                : path
        ),
    };
};

const duck = makeReduxDuck('ui/discovery/snapshotting', initialState);

const updateAction = duck.defineAction<{
    snapshot: ISnapshot;
    level: number;
    resetChartAttributes: boolean;
    blocked?: boolean;
    fitMap?: boolean;
    resetReports?: boolean;
    actionsToTrigger?: TAction[];
}>(
    'UPDATE',
    (
        {
            activeChartAttributes,
            snapshotPaths,
            currentSnapshot,
            expandedReports,
        },
        { snapshot, level, resetChartAttributes, blocked, fitMap, resetReports }
    ) => ({
        snapshotPaths:
            snapshotPaths.length > 0
                ? snapshotPaths.map((path, index) =>
                      index === currentSnapshot.pathIndex
                          ? path.slice(0, level).concat(snapshot)
                          : path
                  )
                : [[snapshot]],
        currentSnapshot: {
            ...currentSnapshot,
            level: level + 1,
        },
        mapFitToExtentIsPending: fitMap !== undefined ? fitMap : true,
        activeChartAttributes: resetChartAttributes
            ? {}
            : activeChartAttributes,
        timeLocked: blocked,
        expandedReports: resetReports ? {} : expandedReports,
    })
);

const toggleGridFullscreenAction = duck.defineAction<{
    sourceSetId: string;
}>(
    'TOGGLE_GRID_FULLSCREEN',
    ({ snapshotPaths, currentSnapshot: { pathIndex } }, { sourceSetId }) =>
        updateGridWithValue(
            snapshotPaths,
            pathIndex,
            sourceSetId,
            'isFullscreen',
            null,
            'toggle'
        )
);

const toggleAnalyticsModeAction = duck.defineAction<{
    sourceSetId: string;
}>(
    'TOGGLE_ANALYTICS_MODE',
    ({ snapshotPaths, currentSnapshot: { pathIndex } }, { sourceSetId }) =>
        updateGridWithValue(
            snapshotPaths,
            pathIndex,
            sourceSetId,
            'isAnalyticsEnabled',
            null,
            'toggle'
        )
);

const setMobileGridVisibilityAction = duck.defineAction<{
    sourceSetId: string;
    visible: boolean;
}>(
    'SET_MOBILE_GRID_VISIBILITY',
    (
        { snapshotPaths, currentSnapshot: { pathIndex } },
        { sourceSetId, visible }
    ) =>
        updateGridWithValue(
            snapshotPaths,
            pathIndex,
            sourceSetId,
            'visibleOnMobile',
            visible
        )
);

const setMobileScrollOffsetAction = duck.defineAction<{
    sourceSetId: string;
    offset: number;
}>(
    'SET_MOBILE_SCROLL_OFFSET',
    (
        { snapshotPaths, currentSnapshot: { pathIndex } },
        { sourceSetId, offset }
    ) =>
        updateGridWithValue(
            snapshotPaths,
            pathIndex,
            sourceSetId,
            'mobileScrollOffset',
            offset
        )
);

const resetLevelAction = duck.defineAction<{ level: number }>(
    'RESET_LEVEL',
    ({ snapshotPaths, currentSnapshot }, { level }) => ({
        snapshotPaths:
            snapshotPaths.length > 0
                ? snapshotPaths.map((path, index) =>
                      index === currentSnapshot.pathIndex
                          ? path.slice(0, level)
                          : path
                  )
                : snapshotPaths,
        currentSnapshot: {
            ...currentSnapshot,
            level,
        },
        calibrationChart: null,
    })
);

const setSnapshotLevelAction = duck.defineAction<{
    pathIndex: number;
    level: number;
}>('SET_SNAPSHOT_LEVEL', (_, { pathIndex, level }) => ({
    currentSnapshot: {
        pathIndex,
        level,
    },
}));

const restoreSnapshotAction = duck.defineAction<{
    currentSnapshot: any;
    snapshotPaths: any;
}>('RESTORE_SNAPSHOT', (_, { currentSnapshot, snapshotPaths }) => ({
    currentSnapshot,
    snapshotPaths,
}));

const restoreMonitoringStateAction = duck.defineAction<{
    dataToRestore: IMonitoringRestorableData;
}>(
    'RESTORE_MONITORING_STATE',
    (_, { dataToRestore: { currentSnapshot, snapshotPaths, timeLocked } }) => ({
        currentSnapshot: {
            level: 0,
            pathIndex: currentSnapshot.pathIndex,
        },
        snapshotPaths,
        timeLocked,
        currentSnapshotToRestore: currentSnapshot,
        isStateRestoring: true,
    })
);

const nextSnapshotAction = duck.definePayloadlessAction(
    'NEXT_SNAPSHOT',
    ({ currentSnapshot, snapshotPaths, currentSnapshotToRestore }) => {
        const nextSnapshotInPath =
            snapshotPaths.length > 0 &&
            snapshotPaths[currentSnapshot.pathIndex][currentSnapshot.level];

        if (nextSnapshotInPath) {
            return {
                currentSnapshot: {
                    level: currentSnapshot.level + 1,
                    pathIndex: currentSnapshot.pathIndex,
                },
            };
        }
        if (!currentSnapshotToRestore) {
            return {
                currentSnapshot,
                isStateRestoring: true,
            };
        }
        return {
            currentSnapshot: currentSnapshotToRestore,
            isStateRestoring: false,
        };
    }
);

const setRestorerWaitAction = duck.defineAction<{
    state: boolean;
}>('TOGGLE_RESTORER_WAIT', (_, { state }) => ({
    shouldRestorerWait: state,
}));

const resetRestorerAction = duck.defineAction<{
    state: boolean;
}>('RESET_RESTORER', () => ({
    shouldRestorerWait: false,
    isStateRestoring: false,
}));

const startStateRestoringAction = duck.definePayloadlessAction(
    'START_RESTORING_STATE',
    () => ({
        shouldRestorerWait: true,
        isStateRestoring: true,
    })
);

const toggleChartAttributeAction = duck.defineAction<{
    attributeId: string;
}>('TOGGLE_CHART_ATTRIBUTE', (state, { attributeId }) => ({
    activeChartAttributes: {
        ...state.activeChartAttributes,
        [attributeId]: !state.activeChartAttributes[attributeId],
    },
}));

const toggleCalibrationActiveAttributes = duck.defineAction<{
    attribute: string;
}>(
    'TOGGLE_CALIBRATION_ACTIVE_ATTRIBUTES',
    ({ snapshotPaths, currentSnapshot: { pathIndex } }, { attribute }) => {
        let activeAttributes = {};

        snapshotPaths[pathIndex].forEach((el) => {
            if (el.type === 'calibrationSetting') {
                activeAttributes = el.calibrationPane?.activeAttributes || {};
            }
        });

        return updateCalibrationWithValue(
            snapshotPaths,
            pathIndex,
            'activeAttributes',
            {
                ...activeAttributes,
                [attribute]: !activeAttributes[attribute],
            }
        );
    }
);

const toggleDetectionActiveAttributes = duck.defineAction<{
    attribute: string;
}>(
    'TOGGLE_DETECTION_ACTIVE_ATTRIBUTES',
    ({ snapshotPaths, currentSnapshot: { pathIndex } }, { attribute }) => {
        let activeAttributes = {};

        snapshotPaths[pathIndex].forEach((el) => {
            if (el.type === 'refuelingAndFuelLossDetectionSettings') {
                activeAttributes = el.detectionPane?.activeAttributes || {};
            }
        });

        return updateDetectionWithValue(
            snapshotPaths,
            pathIndex,
            'activeAttributes',
            {
                ...activeAttributes,
                [attribute]: !activeAttributes[attribute],
            }
        );
    }
);

const toggleCalibrationTypeAction = duck.defineAction<{
    type: 'time' | 'distance';
}>(
    'TOGGLE_CALIBRATION_CHART_ATTRIBUTE',
    ({ snapshotPaths, currentSnapshot: { pathIndex } }, { type }) =>
        updateCalibrationWithValue(snapshotPaths, pathIndex, 'type', type)
);

const toggleExpandReportAction = duck.defineAction<{
    reportName: string;
}>('TOGGLE_REPORT_EXPAND', (state, { reportName }) => ({
    expandedReports: {
        ...state.expandedReports,
        [reportName]: !state.expandedReports[reportName],
    },
}));

const queueMapFitToExtentAction = duck.definePayloadlessAction(
    'QUEUE_MAP_FIT_TO_EXTENT',
    () => ({
        mapFitToExtentIsPending: true,
    })
);

const performMapFitToExtentAction = duck.definePayloadlessAction(
    'PERFORM_MAP_FIT_TO_EXTENT',
    () => ({
        mapFitToExtentIsPending: false,
    })
);

const setDatesAction = duck.defineAction<{
    sourceSetId: string;
    from: string;
    to: string;
}>(
    'SET_SOURCESET_DATES',
    (
        { snapshotPaths, currentSnapshot: { pathIndex } },
        { sourceSetId, from, to }
    ) =>
        updateGridWithValue(
            snapshotPaths,
            pathIndex,
            sourceSetId,
            'sourceSetDates',
            { from, to }
        )
);

const setGridFiltersAction = duck.defineAction<{
    sourceSetId: string;
    filters: any;
}>(
    'SET_GRID_FILTERS_ACTION',
    (
        { snapshotPaths, currentSnapshot: { pathIndex } },
        { sourceSetId, filters }
    ) => {
        return updateGridWithValue(
            snapshotPaths,
            pathIndex,
            sourceSetId,
            'filters',
            filters
        );
    }
);

const setNotAssignedFilterAction = duck.defineAction<{
    sourceSetId: string;
    onlyNotAssigned: boolean;
}>(
    'SET_NOT_ASSIGNED_FILTER',
    (
        { snapshotPaths, currentSnapshot: { pathIndex } },
        { sourceSetId, onlyNotAssigned }
    ) =>
        updateGridWithValue(
            snapshotPaths,
            pathIndex,
            sourceSetId,
            'onlyNotAssigned',
            onlyNotAssigned
        )
);

const setNotPlannedfFilterAction = duck.defineAction<{
    sourceSetId: string;
    onlyNotPlanned: boolean;
}>(
    'SET_NOT_PLANNED_FILTER',
    (
        { snapshotPaths, currentSnapshot: { pathIndex } },
        { sourceSetId, onlyNotPlanned }
    ) =>
        updateGridWithValue(
            snapshotPaths,
            pathIndex,
            sourceSetId,
            'onlyNotPlanned',
            onlyNotPlanned
        )
);

const setIncludeNotPlannedAction = duck.defineAction<{
    sourceSetId: string;
    includeNotPlanned: boolean;
}>(
    'SET_INCLUDE_NOT_PLANNED_FILTER',
    (
        { snapshotPaths, currentSnapshot: { pathIndex } },
        { sourceSetId, includeNotPlanned }
    ) =>
        updateGridWithValue(
            snapshotPaths,
            pathIndex,
            sourceSetId,
            'includeNotPlanned',
            includeNotPlanned
        )
);

const setClusteringAction = duck.defineAction<{
    clustering: boolean;
    level: number;
}>(
    'SET_CLUSTERING',
    (
        { snapshotPaths, currentSnapshot: { pathIndex } },
        { clustering, level }
    ) =>
        updateSnapshotWithValue(
            snapshotPaths,
            pathIndex,
            level,
            'clustering',
            clustering
        )
);

const setActionsToTriggerAction = duck.defineAction<{
    actionsToTrigger: TAction[] | undefined;
    level: number;
}>(
    'SET_ACTIONS_TO_TRIGGER',
    (
        { snapshotPaths, currentSnapshot: { pathIndex } },
        { actionsToTrigger, level }
    ) =>
        updateSnapshotWithValue(
            snapshotPaths,
            pathIndex,
            level,
            'actionsToTrigger',
            actionsToTrigger
        )
);

export const setSourceSetDates = (
    sourceSetId: string,
    from: string,
    to: string
) =>
    setDatesAction({
        sourceSetId,
        from,
        to,
    });

export const setNotAssignedFilter = (
    sourceSetId: string,
    onlyNotAssigned: boolean
) =>
    setNotAssignedFilterAction({
        sourceSetId,
        onlyNotAssigned,
    });

export const setGridFilters = (sourceSetId: string, filters: any) =>
    setGridFiltersAction({
        sourceSetId,
        filters,
    });

export const setNotPlannedFilter = (
    sourceSetId: string,
    onlyNotPlanned: boolean
) =>
    setNotPlannedfFilterAction({
        sourceSetId,
        onlyNotPlanned,
    });

export const setIncludeNotPlanned = (
    sourceSetId: string,
    includeNotPlanned: boolean
) =>
    setIncludeNotPlannedAction({
        sourceSetId,
        includeNotPlanned,
    });

export const setClustering = (clustering: boolean, level: number) =>
    setClusteringAction({ clustering, level });

export const setActionsToTrigger = (
    actionsToTrigger: TAction[] | undefined,
    level: number
) => setActionsToTriggerAction({ actionsToTrigger, level });
export const activateChart = (
    params: { allEvents: string; filteredEvents: string },
    snapshot: { level: number; type: TSnapshotType },
    blocked: boolean = false
) =>
    updateAction({
        snapshot: {
            type: snapshot.type,
            chartPane: {
                params,
                creatorLevel: snapshot.level,
            },
            dataGridPane: null,
            calibrationPane: null,
        },
        level: snapshot.level,
        resetChartAttributes: true,
        blocked,
    });

export const activateGrid = (
    sourceSetId: string,
    snapshot: { level: number; type: TSnapshotType },
    preview: TPreviewPane | null = null,
    isHidden: boolean = false,
    action?: ISourceSetAction,
    blocked: boolean = false,
    isFullscreen: boolean = false,
    isAnalyticsEnabled: boolean = false,
    visibleOnMobile: boolean = true,
    mobileScrollOffset: number = 0,
    resetAttributes?: boolean,
    filters?: { [key: string]: string | number | boolean }
) =>
    updateAction({
        snapshot: {
            type: snapshot.type,
            dataGridPane: {
                sourceSetId,
                sourceSetAction: action || null,
                preview,
                creatorLevel: snapshot.level,
                isHidden,
                isFullscreen,
                isAnalyticsEnabled,
                isChart: snapshot.type === 'source-set-grid-chart',
                visibleOnMobile,
                mobileScrollOffset,
                filters,
                sourceSetDates:
                    action?.params.from && action?.params.to
                        ? {
                              from: String(action?.params.from),
                              to: String(action?.params.to),
                          }
                        : undefined,
            },
            chartPane: null,
            calibrationPane: null,
            selectedId: null,
            clustering: snapshot.type === 'source-set-grid' ? false : undefined,
            actionsToTrigger: action?.actionsToTrigger,
        },
        level: snapshot.level,
        resetChartAttributes: resetAttributes ?? true,
        blocked,
    });

export const activateGridWithSelectedOptions = (params: IActivateGridParams) =>
    activateGridActionWithSelectedParams({
        ...params,
    });

const activatePreviewActionWithSelectedParams = (
    params: IActivateActionParams
) => {
    const {
        elementCollectionName,
        elementId,
        elementType,
        mode,
        snapshot,
        actionType,
        previewAction,
        blocked = false,
        fitMap = true,
        resetReports = false,
        closeGrid = false,
        actionsToTrigger,
    } = params;
    return updateAction({
        snapshot: {
            type: snapshot.type,
            ...(closeGrid && { dataGridPane: null }),
            [actionType]: {
                elementCollectionName,
                previewAction: previewAction || null,
                dialogAction: previewAction || null,
                timestamp: snapshot.timestamp,
                sliderTimeRange: snapshot.sliderTimeRange,
                anonymize: snapshot.anonymize,
                playerSettings: snapshot.playerSettings,
                elementId,
                creatorLevel: snapshot.level,
                elementType,
                mode,
            },
            ...(snapshot.type === 'calibrationSetting' && {
                calibrationPane: {
                    activeAttributes: {
                        y: true,
                        averageY: true,
                        calibratedY: true,
                        refuelingAmount: true,
                    },
                    type: 'distance',
                    creatorLevel: snapshot.level,
                },
            }),
            actionsToTrigger,
            clustering:
                snapshot.type === 'source-set-element' ? false : undefined,
            selectedId: elementId,
        },
        level: snapshot.level,
        resetChartAttributes: false,
        blocked,
        fitMap,
        resetReports,
    });
};

const activateGridActionWithSelectedParams = (params: IActivateGridParams) => {
    const {
        sourceSetId,
        snapshot,
        preview = null,
        isHidden = false,
        action,
        blocked = false,
        isFullscreen = false,
        isAnalyticsEnabled = false,
        visibleOnMobile = true,
        mobileScrollOffset = 0,
        resetAttributes,
        filters,
        actionsToTrigger,
        anonymizeImages,
    } = params;
    return updateAction({
        snapshot: {
            type: snapshot.type,
            dataGridPane: {
                sourceSetId,
                sourceSetAction: action || null,
                preview,
                creatorLevel: snapshot.level,
                isHidden,
                isFullscreen,
                isAnalyticsEnabled,
                isChart: snapshot.type === 'source-set-grid-chart',
                visibleOnMobile,
                mobileScrollOffset,
                filters,
                anonymizeImages,
                sourceSetDates:
                    action?.params.from && action?.params.to
                        ? {
                              from: String(action?.params.from),
                              to: String(action?.params.to),
                          }
                        : undefined,
            },
            chartPane: null,
            calibrationPane: null,
            selectedId: null,
            clustering: snapshot.type === 'source-set-grid' ? false : undefined,
            actionsToTrigger,
        },
        level: snapshot.level,
        resetChartAttributes: resetAttributes ?? true,
        blocked,
    });
};

const activateAction = (
    elementCollectionName: string,
    elementId: string | null,
    elementType: string,
    mode: string,
    snapshot: {
        type: TSnapshotType;
        level: number;
        storeResult?: TFormTypes;
        timestamp?: number;
        sliderTimeRange?: number[];
        anonymize?: boolean;
        playerSettings?: IPlayerSettings;
    },
    actionType: string,
    previewAction?: TDialogAction,
    blocked: boolean = false,
    fitMap: boolean = true,
    resetReports: boolean = false,
    closeGrid: boolean = false
) => {
    return updateAction({
        snapshot: {
            type: snapshot.type,
            ...(closeGrid && { dataGridPane: null }),
            [actionType]: {
                elementCollectionName,
                previewAction: previewAction || null,
                dialogAction: previewAction || null,
                timestamp: snapshot.timestamp,
                sliderTimeRange: snapshot.sliderTimeRange,
                anonymize: snapshot.anonymize,
                playerSettings: snapshot.playerSettings,
                elementId,
                creatorLevel: snapshot.level,
                storeResult: snapshot.storeResult,
                elementType,
                mode,
            },
            ...(snapshot.type === 'calibrationSetting' && {
                calibrationPane: {
                    activeAttributes: {
                        y: true,
                        averageY: true,
                        calibratedY: true,
                        refuelingAmount: true,
                    },
                    type: 'distance',
                    creatorLevel: snapshot.level,
                },
            }),
            clustering:
                snapshot.type === 'source-set-element' ? false : undefined,
            selectedId: elementId,
        },
        level: snapshot.level,
        resetChartAttributes: false,
        blocked,
        fitMap,
        resetReports,
    });
};

export const closeDialog =
    (level: number): ThunkAction<void, null, null, Action<any>> =>
    (dispatch) => {
        dispatch(resetLevel(level));
    };

export const activateDialog = (
    elementCollectionName: string,
    elementId: string | null,
    elementType: string,
    mode: string,
    snapshot: {
        type: TSnapshotType;
        level: number;
        storeResult?: TFormTypes;
        timestamp?: number;
        sliderTimeRange?: number[];
        anonymize?: boolean;
        playerSettings?: IPlayerSettings;
    },
    previewAction?: TDialogAction,
    blocked: boolean = false,
    fitMap: boolean = true,
    resetReports: boolean = false,
    closeGrid: boolean = false
) => {
    return activateAction(
        elementCollectionName,
        elementId,
        elementType,
        mode,
        snapshot,
        'dialog',
        previewAction,
        blocked,
        fitMap,
        resetReports,
        closeGrid
    );
};

export const activatePreviewWithSelectedOptions = (
    params: IActivatePreviewParams
) =>
    activatePreviewActionWithSelectedParams({
        ...params,
        actionType: 'previewPane',
    });

export const activatePreview = (
    elementCollectionName: string,
    elementId: string | null,
    elementType: string,
    mode: string,
    snapshot: { type: TSnapshotType; level: number; storeResult?: TFormTypes },
    previewAction?: IPreviewAction,
    blocked: boolean = false,
    fitMap: boolean = true,
    resetReports: boolean = false,
    closeGrid: boolean = false
) =>
    activateAction(
        elementCollectionName,
        elementId,
        elementType,
        mode,
        snapshot,
        'previewPane',
        previewAction,
        blocked,
        fitMap,
        resetReports,
        closeGrid
    );
export const activateReportSet = (
    monitoredId: string,
    from: string,
    to: string,
    snapshot: { type: TSnapshotType; level: number },
    blocked: boolean = false,
    closeGrid: boolean = true,
    gridId?: string
) =>
    updateAction({
        snapshot: {
            type: snapshot.type,
            ...(closeGrid && { dataGridPane: null }),
            previewPane: {
                elementCollectionName: 'reportSets',
                elementId: simulateProperDataForPresentation(
                    {},
                    +monitoredId,
                    from,
                    to
                ).id,
                reportSetParams: {
                    monitoredId,
                    from,
                    to,
                    objectId: monitoredId,
                },
                previewAction: null,
                creatorLevel: snapshot.level,
                elementType: 'reportSet',
                mode: 'preview',
            },
            selectedId: gridId || null,
        },
        level: snapshot.level,
        resetChartAttributes: true,
        blocked,
    });

export const activateReportSetDataSegments = (
    selectedSegments: IBookmark[],
    snapshot: { type: TSnapshotType; level: number },
    blocked: boolean = false,
    closeGrid: boolean = true,
    gridId?: string
) =>
    updateAction({
        snapshot: {
            type: snapshot.type,
            ...(closeGrid && { dataGridPane: null }),
            previewPane: {
                elementCollectionName: 'reportSets',
                elementId: 'reportSetDataSegments',
                dataSegmentsReports: selectedSegments,
                previewAction: null,
                creatorLevel: snapshot.level,
                elementType: 'reportSetDataSegments',
                mode: 'preview',
            },
            selectedId: gridId || null,
        },
        level: snapshot.level,
        resetChartAttributes: true,
        blocked,
    });

export const activateCalibrationPane = (
    snapshot: { level: number; type: TSnapshotType },
    blocked: boolean = false
) =>
    updateAction({
        snapshot: {
            type: snapshot.type,
            calibrationPane: {
                activeAttributes: {},
                type: 'distance',
                creatorLevel: snapshot.level,
            },
            chartPane: null,
            dataGridPane: null,
            selectedId: null,
        },
        level: snapshot.level,
        resetChartAttributes: true,
        blocked,
    });

export const activateDetectionSettingsPane = (
    snapshot: { level: number; type: TSnapshotType },
    elementId: string | null,
    elementType: string,
    previewAction: IPreviewAction | null,
    blocked: boolean = false,
    fitMap: boolean = false
) =>
    updateAction({
        snapshot: {
            type: snapshot.type,
            detectionPane: {
                activeAttributes: {
                    raw: true,
                    processed: true,
                },
                creatorLevel: snapshot.level,
            },
            previewPane: {
                elementCollectionName: sourceSetIds.lastStates,
                creatorLevel: snapshot.level,
                mode: 'add',
                elementId,
                previewAction,
                elementType,
            },
            calibrationPane: null,
            chartPane: null,
            dataGridPane: null,
            selectedId: null,
        },
        level: snapshot.level,
        resetChartAttributes: true,
        blocked,
        fitMap,
    });

export const updateCalibrationChartAttribute = (attribute: string) =>
    toggleCalibrationActiveAttributes({ attribute });

export const updateDetectionChartAttribute = (attribute: string) =>
    toggleDetectionActiveAttributes({ attribute });

export const updateCalibrationChartType = (type: 'time' | 'distance') =>
    toggleCalibrationTypeAction({ type });

export const toggleGridFullscreen = (sourceSetId: string) =>
    toggleGridFullscreenAction({ sourceSetId });

export const toggleAnalyticsMode = (sourceSetId: string) =>
    toggleAnalyticsModeAction({ sourceSetId });

export const setMobileGridVisibility = (
    sourceSetId: string,
    visible: boolean
) => setMobileGridVisibilityAction({ sourceSetId, visible });

export const setMobileScrollOffset = (sourceSetId: string, offset: number) =>
    setMobileScrollOffsetAction({ sourceSetId, offset });

export const restoreSnapshot = (currentSnapshot: any, snapshotPaths: any) =>
    restoreSnapshotAction({ currentSnapshot, snapshotPaths });

export const restoreMonitoringState = (
    dataToRestore: IMonitoringRestorableData
) => restoreMonitoringStateAction({ dataToRestore });

export const nextSnapshot = () => nextSnapshotAction();

export const notifyRestorerToWait = () =>
    setRestorerWaitAction({ state: true });
export const notifyRestorerToContinue = () =>
    setRestorerWaitAction({ state: false });
export const resetRestorer = () => resetRestorerAction({ state: false });

export const startRestoringState = () => startStateRestoringAction();

export const resetLevel = (level: number) =>
    resetLevelAction({
        level,
    });

export const setSnapshotLevel = (pathIndex: number, level: number) =>
    setSnapshotLevelAction({
        pathIndex,
        level,
    });

export const handleChartAttributeUpdate =
    (attributeId: string): ThunkAction<void, null, null, Action> =>
    (dispatch) => {
        dispatch(toggleChartAttributeAction({ attributeId }));
    };

export const handleExpandReportUpdate =
    (reportName: string): ThunkAction<void, null, null, Action> =>
    (dispatch) => {
        dispatch(toggleExpandReportAction({ reportName }));
    };

export const queueMapFitToExtent = () => queueMapFitToExtentAction();
export const performMapFitToExtent = () => performMapFitToExtentAction();

export default duck.getReducer();
