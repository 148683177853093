import React, { useEffect, useState } from 'react';

import { compose } from 'redux';

import { Field, FieldConfig, FormikErrors, FormikValues } from 'formik';

import { useSnackbar, VariantType } from 'notistack';

import LinearProgress from '@material-ui/core/LinearProgress';

import { Connectable, TConnectableProps } from './Connectable.hoc';
import { useStyles } from './Themable.hooks';
import { IVehicle } from '../../../../../../state/app/registers/vehicles';
import { ITank } from '../../../../../../state/app/registers/tanks';
import { IEmployee } from '../../../../../../state/app/registers/employees';

import { restGetTimezoneDictionary } from '../../../../../../services/registers';
import { restGetGroups } from '../../../../../../services/groups';

import { responseMessage } from '../../../../../../helpers/responseMessage';
import TranslationHelper from '../../../../../../helpers/TranslationHelper';
import { trimValue } from '../../../../../../helpers/TrimValuesHelper';

import { useAppDispatch } from '../../../../../../hooks';

import AsyncSelectField from '../../../../../../components/AsyncSelectField';
import CrudPane from '../../../../../../components/CrudPane/CrudPane';
import PaneHeader from '../../../../../../components/PaneHeader';
import CustomAutocomplete from '../../../../../../components/common/CustomAutocomplete';

import {
    dataFetch,
    fields,
    prepareData,
    saveData,
    schemas,
    TFormData,
} from './utils';

type TObjectData = IVehicle & IEmployee & ITank;

interface FormField extends FieldConfig {
    required?: boolean;
}

type TProps = TConnectableProps;

const EditObject = ({ closePreview, creatorLevel, lastState }: TProps) => {
    const classes = useStyles();
    const [data, setData] = useState<TFormData | undefined>(undefined);

    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useAppDispatch();

    const type = lastState?._meta?.header?.type;

    useEffect(() => {
        if (!data && lastState?._meta?.header?.externalId && type) {
            dataFetch[type](lastState._meta.header.externalId).then(
                (objectData) => {
                    setData(objectData);
                }
            );
        }
    });

    const handleClose = () => {
        closePreview(creatorLevel);
    };

    const handleSubmit = (
        values: FormikValues,
        onSuccess: () => void,
        onError: () => void
    ) => {
        if (!type) {
            return;
        }
        dispatch(
            saveData[type](
                prepareData[type](values) as TObjectData,
                undefined,
                () => {
                    enqueueSnackbar(
                        TranslationHelper.translate(
                            'Update saved successfully'
                        ),
                        { variant: 'success' }
                    );
                    onSuccess();
                },
                (error) => {
                    const jsonMessage = error?.responseJSON?.message;
                    const message =
                        (jsonMessage && {
                            text: jsonMessage,
                            type: 'error' as VariantType,
                        }) ||
                        responseMessage(error.status);
                    enqueueSnackbar(TranslationHelper.translate(message.text), {
                        variant: message.type,
                    });
                    onError();
                }
            )
        );
    };
    if (!type || !data) {
        return (
            <>
                <PaneHeader
                    onCloseClick={handleClose}
                    title={TranslationHelper.translate('Edit object')}
                />
                <LinearProgress />
            </>
        );
    }
    return (
        <CrudPane
            titles={{ preview: 'Edit object' }}
            loaded={true}
            initialValues={data}
            validationSchema={type && schemas[type]()}
            onClose={handleClose}
            changeModeHandler={handleClose}
            mode={'edit'}
            onEdit={handleSubmit}
            renderContent={(
                mode,
                errors: FormikErrors<TFormData>,
                values,
                setFieldValue
            ) => {
                return (
                    <div className={classes.paneContent}>
                        {fields[type](errors, classes)?.map(
                            (field: FormField, index: number) => {
                                if (field.type === 'tz') {
                                    return (
                                        <AsyncSelectField
                                            key={'field' + index}
                                            label={TranslationHelper.translate(
                                                'Time zone'
                                            )}
                                            getOptions={
                                                restGetTimezoneDictionary
                                            }
                                            error={errors?.timeZone}
                                            onChange={(e, value) =>
                                                setFieldValue('timeZone', value)
                                            }
                                            value={data.timeZone}
                                            disableClearable={true}
                                        />
                                    );
                                }
                                if (field.type === 'groups') {
                                    return (
                                        <CustomAutocomplete
                                            key={'field' + index}
                                            value={values.groups || []}
                                            inputTexts={{
                                                placeholder: 'Select group',
                                            }}
                                            label={TranslationHelper.translate(
                                                'Group'
                                            )}
                                            multi
                                            onChange={(e, value) => {
                                                setFieldValue('groups', value);
                                            }}
                                            error={errors?.groups}
                                            onOpen={restGetGroups}
                                            required={field.required}
                                            optionKeys={{
                                                name: 'name',
                                                value: 'id',
                                            }}
                                        />
                                    );
                                }
                                return (
                                    <Field
                                        {...field}
                                        key={'field' + index}
                                        onBlur={(event: any) => {
                                            if (
                                                typeof event.target.value ===
                                                'string'
                                            ) {
                                                trimValue(event, setFieldValue);
                                            }
                                        }}
                                    />
                                );
                            }
                        )}
                    </div>
                );
            }}
        />
    );
};

export default compose(Connectable)(EditObject);
