import Moment, { Moment as IMoment } from 'moment-timezone';
import 'moment-duration-format';

type TUnitOfTime =
    | 'year'
    | 'years'
    | 'y'
    | 'month'
    | 'months'
    | 'M'
    | 'week'
    | 'weeks'
    | 'w'
    | 'day'
    | 'days'
    | 'd'
    | 'hour'
    | 'hours'
    | 'h'
    | 'minute'
    | 'minutes'
    | 'm'
    | 'second'
    | 'seconds'
    | 's'
    | 'millisecond'
    | 'milliseconds'
    | 'ms';

class TimeFormatter {
    public static ISO_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSSZ';

    public static getISOString(date: string | IMoment) {
        return Moment(date).format('YYYYMMDD\\THHmmss.SSSZZ');
    }

    public static toISOString(date: string | IMoment) {
        return Moment(date).format(TimeFormatter.ISO_FORMAT);
    }

    public static toUTCLocalString(date: string | IMoment) {
        return Moment(date).utc().format('YYYY-MM-DDTHH:mm:ss');
    }

    public static toLocalDate(date: string | IMoment) {
        return Moment(date).local().format();
    }

    public static dateToString(date: string, dateFormat: string) {
        return Moment(date).format(dateFormat);
    }

    public static dateToJSDate(date: string | Date) {
        let momentDate = date;
        if (typeof date === 'string') {
            momentDate = Moment(date, 'YYYYMMDD\\THHmmssZ').toDate();
        }

        return momentDate;
    }

    public static fromString(dateString: string) {
        Moment(dateString, 'YYYYMMDD\\THHmmssZ');
    }

    public static dateToShortDateString(date: string | number | IMoment) {
        return Moment(date).format('YYYY-MM-DD');
    }

    public static dateToDateTimeString(date: string | number | IMoment) {
        return Moment(date).format('YYYY-MM-DD HH:mm:ss');
    }

    public static toTimeZonedDate(date: any, timeZone: string) {
        let dateString;
        if (Moment.isMoment(date)) {
            dateString = date.format();
        } else {
            dateString = date.toString();
        }
        return Moment.tz(dateString, timeZone);
    }
    public static toTimeZonedUTCString(date: string, timeZone: string) {
        return Moment.tz(
            TimeFormatter.toUTCLocalString(date),
            timeZone
        ).format();
    }
    public static formatDuration(
        value: number,
        unitOfTime: TUnitOfTime = 'seconds',
        format: string = 'hh:mm:ss'
    ) {
        return Moment.duration(value, unitOfTime).format(format, {
            trim: false,
        });
    }

    public static subtractedDate(days: number) {
        return Moment().subtract(days, 'days').toObject();
    }

    public static subtractedDateString(
        amount: number,
        type: TUnitOfTime = 'days'
    ) {
        return Moment().subtract(amount, type).format('YYYYMMDD\\THHmmss');
    }

    public static endOfDay(date: string | IMoment) {
        return Moment(date).endOf('day').format();
    }

    public static startOfDay(date: string | IMoment) {
        return Moment(date).startOf('day').format();
    }

    public static toLocalStartOfDay(date: string | IMoment) {
        return Moment(date).startOf('day').format('YYYY-MM-DDTHH:mm:ss');
    }
}

export default TimeFormatter;
