import React from 'react';

import { connect } from 'react-redux';

import { instanceOf } from 'prop-types';

import { Connectable } from '../../Connectable.hoc';

import { restGetTimezoneDictionary } from '../../../../services/registers';
import { restGetGroups } from '../../../../services/groups';

import Employee from '../../../../models/registers/Employee';

import Translator from '../../../../helpers/TranslationHelper';

import PrimaryButton from '../../../button/PrimaryButton';
import SecondaryButton from '../../../button/SecondaryButton';
import ValidationMessage from '../../../common/ValidationMessage';
import CustomAutocomplete from '../../../common/CustomAutocomplete';
import Dialog from '../../../dialog/Dialog';
import AsyncFilterSelect from '../../../AsyncFilterSelect';

const makeName = (name, firstName, surname) => {
    if (document.activeElement.name === 'name') {
        return name;
    }

    if (name) {
        return name.trim();
    }

    if (firstName && surname) {
        return `${surname} ${firstName}`;
    }

    return firstName || surname;
};

const initName = ({ name, firstName, surname }) =>
    name === `${surname} ${firstName}` ? '' : name;

class EmployeeDialog extends React.Component {
    state = {
        id: this.props.employee.id || undefined,
        firstName: this.props.employee.firstName || '',
        surname: this.props.employee.surname || '',
        name: initName(this.props.employee),
        email: this.props.employee.email || '',
        phoneNumber: this.props.employee.phoneNumber || '',
        mobileNumber: this.props.employee.mobileNumber || '',
        groups: this.props.employee.groups || [],
        timeZone: this.props.employee.timeZone,
        address: {
            street: this.props.employee.address.street || '',
            houseNumber: this.props.employee.address.houseNumber || '',
            apartmentNumber: this.props.employee.address.apartmentNumber || '',
            city: this.props.employee.address.city || '',
            postalCode: this.props.employee.address.postalCode || '',
        },

        availableEmployeeGroups: undefined,
        validationMessage: undefined,
        loading: false,
    };

    componentDidMount() {
        this._isMounted = true;
        this.firstName.focus();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleGroupChange = (e, groups) => {
        this.setState({ groups });
    };

    handleTimeZoneChange = (option) => {
        option &&
            this.setState({
                timeZone: {
                    id: option.id,
                    name: option.name,
                },
            });
    };

    handleInputChange = ({ target: { name, value } }) => {
        this.setState({
            [name]: value.length > 1 ? value : value.trim(),
        });
    };

    handleAddressInputChange = ({ target: { name, value } }) =>
        this.setState({
            address: {
                ...this.state.address,
                [name]: value,
            },
        });

    handleSubmit = (event) => {
        event.preventDefault();
        const {
            employeesFilter: filter,
            updateEmployee,
            createEmployee,
        } = this.props;
        const data = this._prepareEmployeeData();

        this.setState({
            loading: true,
        });
        const successCallback = (validation) => {
            if (validation.success) {
                this.props.hideGlobalDialog();
            } else if (validation.messages) {
                if (this._isMounted) {
                    this.setState({
                        validationMessage: validation.messages[0],
                        loading: false,
                    });
                }
            }
        };
        const failCallback = () => {
            if (this._isMounted) {
                this.setState({
                    validationMessage: Translator.translate(
                        'Unrecognized error has occurred'
                    ),
                    loading: false,
                });
            }
        };
        const action = data.id ? updateEmployee : createEmployee;

        action(
            data,
            filter,
            (validation) => successCallback(validation),
            () => failCallback()
        );
    };

    handleCancel = (event) => {
        event.preventDefault();
        this.props.hideGlobalDialog();
    };

    _prepareEmployeeData = () => {
        const {
            // eslint-disable-next-line no-unused-vars
            validationMessage,
            availableEmployeeGroups,
            address,
            loading,
            groups,
            timeZone,
            ...formData
        } = this.state;

        return this.props.employee.merge({
            ...formData,
            name: this.name.value.trim(),
            groups: undefined,
            groupIds: groups.map((group) => group.id),
            timeZone,
            address: this.props.employee.address.merge(address),
        });
    };

    render() {
        const {
            id,
            firstName,
            surname,
            name,
            email,
            phoneNumber,
            mobileNumber,
            timeZone,
            address: {
                street,
                houseNumber,
                apartmentNumber,
                city,
                postalCode,
            } = {},
            groups,
            // eslint-disable-next-line no-unused-vars
            validationMessage,
            loading,
        } = this.state;

        return (
            <Dialog
                title={
                    id === undefined
                        ? Translator.translate('New employee')
                        : Translator.translate('Edit employee')
                }
                onClose={this.handleCancel}
                classNames="dialog employees-dialog"
            >
                <form
                    className="form employee-form"
                    onSubmit={this.handleSubmit}
                    autoComplete="off"
                >
                    <div className="section">
                        <div className="form-group single">
                            <div className="form-title">
                                {Translator.translate('Name')}
                            </div>

                            <div className="form-input">
                                <input
                                    type="text"
                                    className="mtl-input"
                                    name="name"
                                    ref={(element) => (this.name = element)}
                                    onChange={this.handleInputChange}
                                    onBlur={this.handleInputChange}
                                    placeholder={
                                        Translator.translate(
                                            'E.g. John Smith'
                                        ) + ' '
                                    }
                                    value={makeName(name, firstName, surname)}
                                    required
                                    maxLength="50"
                                />
                            </div>
                        </div>

                        <div className="form-group single">
                            <div className="form-title">
                                {Translator.translate('Group')}
                            </div>

                            <div className="form-input">
                                <CustomAutocomplete
                                    value={groups}
                                    inputTexts={{ placeholder: 'Select group' }}
                                    multi
                                    onChange={this.handleGroupChange}
                                    onOpen={restGetGroups}
                                    optionKeys={{ name: 'name', value: 'id' }}
                                    required
                                />
                            </div>
                        </div>

                        <div className="form-group">
                            <div className="form-title">
                                {Translator.translate('First name')}
                            </div>

                            <div className="form-input">
                                <input
                                    type="text"
                                    className="mtl-input"
                                    name="firstName"
                                    ref={(element) =>
                                        (this.firstName = element)
                                    }
                                    onChange={this.handleInputChange}
                                    placeholder={
                                        Translator.translate('E.g. John') + ' '
                                    }
                                    value={firstName}
                                    required
                                    maxLength="255"
                                />
                            </div>
                        </div>

                        <div className="form-group">
                            <div className="form-title">
                                {Translator.translate('Surname')}
                            </div>

                            <div className="form-input">
                                <input
                                    type="text"
                                    className="mtl-input"
                                    name="surname"
                                    ref={(element) => (this.surname = element)}
                                    onChange={this.handleInputChange}
                                    placeholder={
                                        Translator.translate('E.g. Smith') + ' '
                                    }
                                    value={surname}
                                    required
                                    maxLength="255"
                                />
                            </div>
                        </div>

                        <div className="form-group single">
                            <div className="form-title">
                                {Translator.translate('Time zone')}
                            </div>
                            <div className="form-input">
                                <AsyncFilterSelect
                                    name="timeZone"
                                    selectedOptionId={timeZone && timeZone.id}
                                    getData={() => restGetTimezoneDictionary()}
                                    clearable={false}
                                    required
                                    value={timeZone}
                                    placeholder={Translator.translate(
                                        'Select time zone'
                                    )}
                                    onOptionSelect={this.handleTimeZoneChange}
                                />
                            </div>
                        </div>

                        <div className="form-group triple">
                            <div className="form-title">
                                {Translator.translate('Email')}
                            </div>

                            <div className="form-input">
                                <input
                                    type="email"
                                    className="mtl-input"
                                    name="email"
                                    onChange={this.handleInputChange}
                                    placeholder={
                                        Translator.translate(
                                            'E.g. john.smith@example.com'
                                        ) + ' '
                                    }
                                    defaultValue={email}
                                    maxLength="255"
                                />
                            </div>
                        </div>

                        <div className="form-group triple">
                            <div className="form-title">
                                {Translator.translate('Phone')}
                            </div>

                            <div className="form-input">
                                <input
                                    type="text"
                                    className="mtl-input"
                                    name="phoneNumber"
                                    onChange={this.handleInputChange}
                                    placeholder={
                                        Translator.translate(
                                            'E.g. +48123456789'
                                        ) + ' '
                                    }
                                    value={phoneNumber}
                                    maxLength="255"
                                />
                            </div>
                        </div>

                        <div className="form-group triple">
                            <div className="form-title">
                                {Translator.translate('Mobile')}
                            </div>

                            <div className="form-input">
                                <input
                                    type="text"
                                    className="mtl-input"
                                    name="mobileNumber"
                                    onChange={this.handleInputChange}
                                    placeholder={
                                        Translator.translate(
                                            'E.g. +48123456789'
                                        ) + ' '
                                    }
                                    value={mobileNumber}
                                    maxLength="255"
                                />
                            </div>
                        </div>

                        <div className="form-group triple">
                            <div className="form-title">
                                {Translator.translate('Street')}
                            </div>

                            <div className="form-input">
                                <input
                                    type="text"
                                    className="mtl-input"
                                    name="street"
                                    onChange={this.handleAddressInputChange}
                                    placeholder={
                                        Translator.translate(
                                            'E.g. Baker Street'
                                        ) + ' '
                                    }
                                    value={street}
                                    maxLength="255"
                                />
                            </div>
                        </div>

                        <div className="form-group triple">
                            <div className="form-title">
                                {Translator.translate('House number')}
                            </div>

                            <div className="form-input">
                                <input
                                    type="text"
                                    className="mtl-input"
                                    name="houseNumber"
                                    onChange={this.handleAddressInputChange}
                                    placeholder={
                                        Translator.translate('E.g. 10a') + ' '
                                    }
                                    value={houseNumber}
                                    maxLength="50"
                                />
                            </div>
                        </div>

                        <div className="form-group triple">
                            <div className="form-title">
                                {Translator.translate('Flat number')}
                            </div>

                            <div className="form-input">
                                <input
                                    type="text"
                                    className="mtl-input"
                                    name="apartmentNumber"
                                    onChange={this.handleAddressInputChange}
                                    placeholder={
                                        Translator.translate('E.g. 13') + ' '
                                    }
                                    value={apartmentNumber}
                                    maxLength="50"
                                />
                            </div>
                        </div>

                        <div className="form-group">
                            <div className="form-title">
                                {Translator.translate('Postal code')}
                            </div>

                            <div className="form-input">
                                <input
                                    type="text"
                                    className="mtl-input"
                                    name="postalCode"
                                    onChange={this.handleAddressInputChange}
                                    placeholder={
                                        Translator.translate('E.g. EC1') + ' '
                                    }
                                    value={postalCode}
                                    maxLength="10"
                                />
                            </div>
                        </div>

                        <div className="form-group">
                            <div className="form-title">
                                {Translator.translate('City')}
                            </div>

                            <div className="form-input">
                                <input
                                    type="text"
                                    className="mtl-input"
                                    name="city"
                                    onChange={this.handleAddressInputChange}
                                    placeholder={
                                        Translator.translate('E.g. London') +
                                        ' '
                                    }
                                    value={city}
                                    maxLength="255"
                                />
                            </div>
                        </div>

                        <div className="form-group">
                            <ValidationMessage
                                message={Translator.translate(
                                    validationMessage
                                )}
                                error={validationMessage !== undefined}
                            />
                        </div>
                    </div>

                    <div className="form-actions">
                        <PrimaryButton
                            icon="icon-floppy-disk"
                            label={Translator.translate('Save')}
                            type="submit"
                            disabled={loading}
                        />

                        <SecondaryButton
                            onClick={this.handleCancel}
                            icon="icon-cross"
                            label={Translator.translate('Cancel')}
                        />
                    </div>
                </form>
            </Dialog>
        );
    }
}

EmployeeDialog.propTypes = {
    employee: instanceOf(Employee),
};

export default Connectable(EmployeeDialog);
