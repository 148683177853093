import React from 'react';
import { default as List } from '@material-ui/core/List';
import { default as ListItem } from '@material-ui/core/ListItem';
import { default as ListItemText } from '@material-ui/core/ListItemText';
import { Hidden } from '@material-ui/core';
import { default as IconButton } from '@material-ui/core/IconButton';
import { default as Tooltip } from '@material-ui/core/Tooltip';
import TranslationHelper from '../../../../helpers/TranslationHelper';
import { default as PlusIcon } from '@material-ui/icons/Add';
import { default as ExpandLess } from '@material-ui/icons/ExpandLess';
import { default as ExpandMore } from '@material-ui/icons/ExpandMore';
import VisualHint from '../VisualHint';
import { default as Collapse } from '@material-ui/core/Collapse';
import ReportListItem from './components/ReportListItem';
import { TThemableProps } from './Themable.hoc';
import { ISourceSetAction } from '../../../../state/ui/discovery/types';
import { IActionDataParams, IReport } from '../../../../state/types';
import ReportDetailsIcons from './ReportDetailsIcons';

interface IReportProps {
    open: boolean;
    report: IReport;
    openDeletionDialog: (id: string, message?: string | undefined) => void;
    handleReportAction: (
        type: 'add' | 'edit' | 'preview',
        params?: IActionDataParams
    ) => void;
    handleEntityAction: (
        type: 'add' | 'edit' | 'preview',
        params: IActionDataParams
    ) => void;
    handleOpenSourceSetClick: (action: ISourceSetAction | undefined) => void;
    handleOpenChartClick: () => void;
    handleOpenGridChartClick: () => void;
    handleClick: () => void;
}

type TProps = IReportProps & TThemableProps;

const ReportList: React.FC<TProps> = ({
    open,
    classes,
    report,
    openDeletionDialog,
    handleReportAction,
    handleOpenChartClick,
    handleOpenSourceSetClick,
    handleClick,
    handleEntityAction,
    handleOpenGridChartClick,
}: TProps) => {
    const weHaveEntities = report.entities?.length > 0;
    let hasAddAction = false;
    let useVisualHint;

    if (report._meta) {
        const meta = report._meta;
        useVisualHint = meta.componentName === 'visualHint';
        if (meta.actions) {
            hasAddAction = meta.actions.add !== undefined;
        }
    }
    return (
        <>
            <ListItem disableGutters={true} className={classes.listItemHeading}>
                <ListItemText
                    primary={
                        report.labelParams
                            ? TranslationHelper.translateFormat(
                                  report.label,
                                  report.labelParams
                              )
                            : TranslationHelper.translate(report.label)
                    }
                    className={classes.heading}
                />
                <ReportDetailsIcons
                    report={report}
                    handleOpenChartClick={handleOpenChartClick}
                    handleOpenGridChartClick={handleOpenGridChartClick}
                    handleOpenSourceSetClick={handleOpenSourceSetClick}
                />
                <Hidden smDown={true}>
                    {hasAddAction && (
                        <Tooltip title={TranslationHelper.translate('Add')}>
                            <IconButton
                                onClick={() => handleReportAction('add')}
                            >
                                <PlusIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                </Hidden>
                <IconButton
                    onClick={handleClick}
                    style={{
                        visibility: weHaveEntities ? 'visible' : 'hidden',
                    }}
                >
                    {open ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
            </ListItem>
            {weHaveEntities && useVisualHint && (
                <Collapse in={open} timeout="auto" unmountOnExit={true}>
                    <VisualHint entities={report.entities} />
                </Collapse>
            )}
            {weHaveEntities && !useVisualHint && (
                <Collapse in={open} timeout="auto" unmountOnExit={true}>
                    <List component="div" disablePadding={true} dense={true}>
                        {report.entities.map((entity: any) => (
                            <ReportListItem
                                key={entity.id}
                                entity={entity}
                                reportActionTypes={report?._meta?.actions}
                                handleReportAction={handleReportAction}
                                handleDelete={openDeletionDialog}
                                handleOpenSourceSetClick={
                                    handleOpenSourceSetClick
                                }
                                handleEntityAction={handleEntityAction}
                                reportId={report.id}
                            />
                        ))}
                    </List>
                </Collapse>
            )}
        </>
    );
};

export default ReportList;
