import React, { Fragment, useEffect, useState } from 'react';

import { default as CircularProgress } from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import RefreshIcon from '@material-ui/icons/Autorenew';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

import TranslationHelper from '../../../../../../../../helpers/TranslationHelper';

import PhotoOverlay from '../../components/PhotoOverlay/PhotoOverlay';
import { usePrivileges } from '../../../../../../../../state/auth/index.hooks';
import { useStyles } from '../../Themable.hooks';
import { Image } from './Image';
import { useDiscoveryUIDialog } from '../../../../../../../../pages/discovery/selectors/index.hooks';

interface IOwnProps {
    classes: ClassNameMap;
    apiPreview: string;
    apiDownload: string;
    name?: string;
    valid?: boolean;
    download?: string;
    type?: string;
    paramName: string;
    reportId: string;
}

function hasQueryParams(url: string) {
    try {
        const parsedUrl = new URL(url, window.location.origin);
        return Array.from(parsedUrl.searchParams).length > 0;
    } catch (error) {
        return false;
    }
}

const ShowImage = ({
    apiPreview,
    apiDownload,
    name,
    valid,
    download,
    type,
    paramName,
    reportId,
}: IOwnProps) => {
    const privileges = usePrivileges();
    const classes = useStyles();

    const dialogData = useDiscoveryUIDialog();

    const anonymizeQueryParam = hasQueryParams(apiPreview)
        ? '&anonymize='
        : '?anonymize=';
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [anonymize, setAnonymize] = useState<boolean>(false);

    const initialAnonymize = dialogData?.anonymize ?? privileges.anonymize;

    const initPlayerSettings = dialogData?.playerSettings;

    const [previewUrl, setPreviewUrl] = useState(
        `${apiPreview}${anonymizeQueryParam}${initialAnonymize}`
    );

    useEffect(() => {
        setAnonymize(initialAnonymize);
    }, []);

    useEffect(() => {
        const updatedPreviewUrl = `${apiPreview}${anonymizeQueryParam}${anonymize}`;
        resetData(updatedPreviewUrl);
    }, [apiPreview, anonymize]);

    const handleError = () => {
        setLoading(false);
        setError(true);
    };

    const resetData = (url: string) => {
        setError(false);
        setLoading(true);
        setPreviewUrl(url);
    };

    const displayOverlay = type === 'movieFrame' || type === 'frameDetails';

    const handleOnLoad = () => {
        setLoading(false);
    };
    return valid ? (
        <Fragment>
            <div
                className={classes.invalidImage}
                title={name}
                style={{ display: loading ? 'flex' : 'none' }}
            >
                <CircularProgress />
            </div>
            <div
                className={classes.photoContainer}
                style={{ display: loading || error ? 'none' : 'block' }}
            >
                <Image
                    name={name}
                    download={download}
                    url={previewUrl}
                    handleError={handleError}
                    handleOnLoad={handleOnLoad}
                    apiDownload={`${apiDownload}${anonymizeQueryParam}${anonymize}`}
                    key={paramName}
                />

                {displayOverlay && (
                    <div className={classes.photoOverlay}>
                        <PhotoOverlay
                            paramName={paramName}
                            type={type}
                            reportId={reportId}
                            anonymize={anonymize}
                            setAnonymize={setAnonymize}
                            initPlayerSettings={initPlayerSettings}
                        />
                    </div>
                )}
            </div>
            <div
                className={classes.invalidImage}
                title={name}
                style={{ display: error ? 'flex' : 'none' }}
            >
                {TranslationHelper.translate('The image has not been found.')}
                <IconButton
                    onClick={() =>
                        resetData(`${apiPreview}&anonymize=${anonymize}`)
                    }
                >
                    <RefreshIcon />
                </IconButton>
            </div>
        </Fragment>
    ) : (
        <div className={classes.invalidImage} title={name}>
            {TranslationHelper.translate('No image')}
        </div>
    );
};

export default ShowImage;
