import React, { useEffect, useState } from 'react';
import { Prompt } from 'react-router-dom';
import { compose } from 'redux';
import { Connectable, TConnectableProps } from './Connectable.hoc';
import { useStyles } from './Themable.hoc';

import Button from '@material-ui/core/Button';
import { useSnackbar } from 'notistack';

import { Field, Formik, FormikActions } from 'formik';
import FieldWrapper from '../../../../../../components/common/formikWrappers/FieldWrapper';
import { responseMessage } from '../../../../../../helpers/responseMessage';
import TranslationHelper from '../../../../../../helpers/TranslationHelper';
import { passwordChangeSchema } from '../../../../../../schemas';
import { restChangePassword } from '../../../../../../services/userSettings';

interface IOwnProps {
    collapseClose: () => void;
}

type TProps = IOwnProps & TConnectableProps;

interface IForm {
    oldPassword: string;
    newPassword: string;
    confirmNewPassword: string;
}

const blankForm: IForm = {
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
};

const ChangePassword = ({
    userId,
    collapseClose,
    setUserSettingsMode,
    login,
}: TProps) => {
    const [formDirty, setFormDirty] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();

    useEffect(() => {
        return () => {
            setUserSettingsMode({ mode: null });
        };
    }, []);

    useEffect(() => {
        setUserSettingsMode({ mode: formDirty ? 'edited' : null });
    }, [formDirty]);

    const handleSubmit = (
        { oldPassword, newPassword }: IForm,
        { resetForm, setFieldError }: FormikActions<IForm>
    ) => {
        if (userId) {
            restChangePassword(oldPassword, newPassword, userId)
                .then(() => {
                    enqueueSnackbar(
                        TranslationHelper.translate('Password changed'),
                        { variant: 'success' }
                    );
                    resetForm();
                    collapseClose();
                })
                .catch((error: any) => {
                    if (error?.status === 400) {
                        setFieldError(
                            'oldPassword',
                            TranslationHelper.translate(
                                'Invalid current password'
                            )
                        );
                    } else {
                        const message = responseMessage(error?.status);
                        enqueueSnackbar(message.text, {
                            variant: message.type,
                        });
                    }
                });
        }
    };

    return (
        <div className={classes.wrapper}>
            <Formik
                enableReinitialize={true}
                initialValues={blankForm}
                onSubmit={handleSubmit}
                validateOnBlur={false}
                validateOnChange={false}
                validationSchema={passwordChangeSchema()}
            >
                {({ errors, submitForm, dirty }) => {
                    if (dirty !== formDirty) {
                        setFormDirty(dirty);
                    }

                    return (
                        <>
                            <Prompt
                                when={dirty}
                                message={TranslationHelper.translate(
                                    'Unsaved data will be lost. Do you want to continue?'
                                )}
                            />
                            <Field
                                name={'username'}
                                autoComplete={'username'}
                                value={login}
                                disabled
                                style={{ display: 'none' }}
                                component={FieldWrapper}
                            />
                            <Field
                                error={!!(errors && errors.oldPassword)}
                                helperText={errors && errors.oldPassword}
                                name={'oldPassword'}
                                label={TranslationHelper.translate(
                                    'Current password'
                                )}
                                type={'password'}
                                fullWidth={true}
                                required={true}
                                autoComplete={'current-password'}
                                component={FieldWrapper}
                            />
                            <Field
                                error={!!(errors && errors.newPassword)}
                                helperText={errors && errors.newPassword}
                                name={'newPassword'}
                                label={TranslationHelper.translate(
                                    'New password'
                                )}
                                type={'password'}
                                fullWidth={true}
                                required={true}
                                autoComplete={'new-password'}
                                component={FieldWrapper}
                            />
                            <Field
                                error={!!(errors && errors.confirmNewPassword)}
                                helperText={errors && errors.confirmNewPassword}
                                name={'confirmNewPassword'}
                                label={TranslationHelper.translate(
                                    'Confirm new password'
                                )}
                                type={'password'}
                                fullWidth={true}
                                required={true}
                                autoComplete={'new-password'}
                                component={FieldWrapper}
                            />

                            <Button
                                color="primary"
                                variant="contained"
                                className={classes.saveButton}
                                onClick={submitForm}
                            >
                                {TranslationHelper.translate('Save')}
                            </Button>
                        </>
                    );
                }}
            </Formik>
        </div>
    );
};

export default compose(Connectable)(ChangePassword);
