import React, { Component, ComponentType, Fragment } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { compose } from 'redux';

import moment from 'moment-timezone';

import classNames from 'classnames';

import { Connectable, TConnectableProps } from './Connectable.hoc';
import { Themable, TThemableProps } from './Themable.hoc';

import {
    ISnapshot,
    TSnapshotType,
} from '../../../../state/ui/discovery/snapshotting';

import { isMobile } from '../../../../helpers/IsMobile';
import { replaceCurrentPath } from '../../../../helpers/replacers';
import TimeFormatter from '../../../../helpers/TimeFormatter';
import TranslationHelper from '../../../../helpers/TranslationHelper';

import { Hidden } from '@material-ui/core';
import { default as Paper } from '@material-ui/core/Paper';
import { default as Tooltip } from '@material-ui/core/Tooltip';
import withWidth, { WithWidthProps } from '@material-ui/core/withWidth';
import { default as NextIcon } from '@material-ui/icons/KeyboardArrowRight';
import isEqual from 'lodash.isequal';
import { IReportSet } from 'src/app/state/types';

type TProps = TThemableProps &
    TConnectableProps &
    RouteComponentProps &
    WithWidthProps;

class Bookmarks extends Component<TProps> {
    public componentDidMount() {
        this.saveToUrlHandler();
    }

    public componentDidUpdate(prevProps: TProps) {
        this.saveToUrlHandler(prevProps);
    }

    public saveToUrlHandler = (prevProps?: TProps) => {
        const {
            location,
            history,
            currentSnapshot,
            snapshotPaths,
            timeLocked,
            isStateRestoring,
            width,
        } = this.props;

        const isCurrentSnapshotEqual = isEqual(
            currentSnapshot,
            prevProps?.currentSnapshot
        );
        const isSnapshotPathsEqual = isEqual(
            snapshotPaths,
            prevProps?.snapshotPaths
        );
        const isTimelockedEqual = timeLocked === prevProps?.timeLocked;
        const shouldUpdateQuery =
            (!isCurrentSnapshotEqual ||
                !isSnapshotPathsEqual ||
                !isTimelockedEqual) &&
            !isStateRestoring;

        if (shouldUpdateQuery) {
            const dataRaw = {
                currentSnapshot,
                snapshotPaths,
                timeLocked,
            };

            replaceCurrentPath(
                { history, location },
                dataRaw,
                false,
                isMobile(width)
            );
        }
    };

    public render() {
        const {
            classes,
            snapshotPaths,
            currentSnapshot,
            sourceSets,
            monitoredObjects,
            locations,
            sourceSetElements,
            reportSets,
            tasks,
            previewPane,
            userSettings,
            editMode,
        } = this.props;

        const formatWithoutSeconds = userSettings.dateFormatWithoutSeconds;
        const dateFormat = userSettings.dateFormat;
        const formatTime = (from?: string, to?: string) =>
            `${moment(from).format(formatWithoutSeconds)} - ${moment(to).format(
                formatWithoutSeconds
            )}`;
        const previewTypes = {
            location: (id: string) => {
                return !locations[id]
                    ? TranslationHelper.translate('Add location')
                    : locations[id] && locations[id].name;
            },
            monitoredObject: (id: string) => {
                const object = monitoredObjects[id];
                return object?._meta?.header?.name;
            },
            reportSet: (id: string) =>
                reportSets[id] &&
                formatTime(reportSets[id].from, reportSets[id].to),
            sourceSetElement: (id: string) =>
                this.handleSourceSetElementBookmark(
                    sourceSetElements,
                    id,
                    dateFormat
                ),
            sourceSetElementMoviePlayer: (id: string, name: string) =>
                `(${TranslationHelper.translate(
                    'movie'
                )}) ${this.handleMovieSourceSetBookmarks(
                    sourceSetElements,
                    id,
                    name,
                    dateFormat
                )}`,
            task: (id: string) => {
                const taskPlannedOn =
                    tasks[id] &&
                    tasks[id]._meta &&
                    (
                        tasks[id]._meta as {
                            header: { task: { plannedOn: string } };
                        }
                    ).header.task.plannedOn;

                return taskPlannedOn
                    ? `${TranslationHelper.translate('Task')} (${moment(
                          taskPlannedOn
                      ).format(userSettings.shortDateFormat)})`
                    : id
                    ? TranslationHelper.translate('Task')
                    : TranslationHelper.translate('Add task');
            },
            alertSetting: () => {
                return previewPane && previewPane.mode === 'add'
                    ? TranslationHelper.translate('Add alert')
                    : TranslationHelper.translate('Edit alert');
            },
            calibrationSetting: () =>
                previewPane?.mode === 'add'
                    ? TranslationHelper.translate('Add calibration')
                    : TranslationHelper.translate('Edit calibration'),
            registerRefueling: () =>
                TranslationHelper.translate('Register refueling event'),
            searchEvents: () =>
                TranslationHelper.translate('Search events nearby'),
            dataSegments: () => TranslationHelper.translate('Data segments'),
            reportSetDataSegments: () =>
                TranslationHelper.translate('Data segments - reports'),
        };

        const getCalibrationHeader = (mode?: string) => {
            switch (mode) {
                case 'add':
                    return TranslationHelper.translate('Add calibration');
                case 'edit':
                    return TranslationHelper.translate('Edit calibration');
                default:
                    return TranslationHelper.translate('Calibration details');
            }
        };

        const getAlertHeader = (mode?: string) => {
            if (mode === 'add') {
                return TranslationHelper.translate('Add alert');
            }
            return TranslationHelper.translate('Edit alert');
        };

        const getLabel = (
            type: TSnapshotType
        ): ((item: ISnapshot) => string) => {
            switch (type) {
                case 'category':
                case 'source-set-grid':
                case 'source-set-grid-chart':
                    return (item: ISnapshot) =>
                        (item.dataGridPane?.sourceSetId &&
                            sourceSets[item.dataGridPane.sourceSetId] &&
                            TranslationHelper.translate(
                                sourceSets[item.dataGridPane.sourceSetId].label
                            )) ||
                        '';
                case 'location':
                    return (item: ISnapshot) => {
                        const id =
                            item.previewPane?.elementCollectionName ===
                            'locations'
                                ? item.previewPane.elementId
                                : item.previewPane?.elementCollectionName;
                        return (
                            (item.previewPane?.elementType &&
                                previewTypes[item.previewPane.elementType] &&
                                TranslationHelper.translate(
                                    previewTypes[item.previewPane.elementType](
                                        id
                                    )
                                )) ||
                            ''
                        );
                    };
                case 'preview':
                case 'report-set':
                case 'source-set-element':
                    return (item: ISnapshot) => {
                        return (
                            (item.previewPane?.elementType &&
                                previewTypes[item.previewPane.elementType] &&
                                TranslationHelper.translate(
                                    previewTypes[item.previewPane.elementType](
                                        item.previewPane.elementId
                                    )
                                )) ||
                            ''
                        );
                    };

                case 'source-set-chart':
                    return () => TranslationHelper.translate('Chart');

                case 'source-set-element-movieplayer':
                    return (item: ISnapshot) =>
                        (item.dialog?.elementType &&
                            previewTypes[item.dialog.elementType] &&
                            TranslationHelper.translate(
                                previewTypes[item.dialog.elementType](
                                    item.dialog.elementId,
                                    item.dialog.elementCollectionName
                                )
                            )) ||
                        '';

                case 'frameDetails':
                    return (item: ISnapshot) => {
                        return (
                            (item.previewPane?.previewAction?.componentName &&
                                item.previewPane?.previewAction?.params &&
                                `${TranslationHelper.translate(
                                    item.previewPane.previewAction.componentName
                                )} ${
                                    item.previewPane.previewAction.params.offset
                                }s`) ||
                            ''
                        );
                    };

                case 'aggregationDetails':
                    return (item: ISnapshot) => {
                        return (
                            (item.previewPane?.previewAction?.componentName &&
                                item.previewPane?.previewAction?.params
                                    ?.startDate &&
                                `${TranslationHelper.translate(
                                    item.previewPane.previewAction.componentName
                                )} ${TimeFormatter.dateToString(
                                    item.previewPane.previewAction.params
                                        .startDate,
                                    dateFormat
                                )}`) ||
                            ''
                        );
                    };

                case 'alertSetting':
                    return (item: ISnapshot) =>
                        getAlertHeader(item.previewPane?.mode);

                case 'calibrationSetting':
                    return (item: ISnapshot) =>
                        getCalibrationHeader(item.previewPane?.mode);

                case 'registerRefueling':
                    return () =>
                        TranslationHelper.translate('Register refueling event');

                case 'refuelingAndFuelLossDetectionSettings':
                    return () =>
                        TranslationHelper.translate('Detection settings');

                case 'devices':
                    return () =>
                        TranslationHelper.translate('Edit parameters mapping');

                default:
                    return () => '';
            }
        };

        return (
            <Hidden smDown={true}>
                <div className={classes.pane}>
                    <ul>
                        {snapshotPaths.map((path, pathIndex) => (
                            <li
                                key={pathIndex}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                {path.map((item, level) => {
                                    const className = classNames({
                                        [classes.breadcrumb]: true,
                                        [classes.activeBreadcrumb]:
                                            currentSnapshot.pathIndex ===
                                                pathIndex &&
                                            currentSnapshot.level - 1 === level,
                                        [classes.disabledBreadcrumb]: editMode,
                                    });
                                    return (
                                        <Fragment key={level}>
                                            <NextIcon
                                                className={
                                                    editMode
                                                        ? classes.disabledBreadcrumb
                                                        : undefined
                                                }
                                            />
                                            <Tooltip
                                                key={level}
                                                title={getLabel(item.type)(
                                                    item as ISnapshot
                                                )}
                                                placement="bottom-start"
                                            >
                                                <Paper
                                                    onClick={this.makeClickHandler(
                                                        pathIndex,
                                                        level + 1
                                                    )}
                                                    className={className}
                                                >
                                                    {getLabel(item.type)(
                                                        item as ISnapshot
                                                    ) || ' '}
                                                </Paper>
                                            </Tooltip>
                                        </Fragment>
                                    );
                                })}
                            </li>
                        ))}
                    </ul>
                </div>
            </Hidden>
        );
    }

    private makeClickHandler = (pathIndex: number, level: number) => () => {
        const { editMode, setSnapshotLevel, queueMapFitToExtent } = this.props;

        if (!editMode) {
            setSnapshotLevel(pathIndex, level);
            queueMapFitToExtent();
        }
    };
    private handleMovieSourceSetBookmarks = (
        sourceSetElements: { [sourceSetElementId: string]: IReportSet },
        id: string,
        name: string,
        dateFormat: string
    ) => {
        return (
            this.handleSourceSetElementBookmark(
                sourceSetElements,
                id,
                dateFormat
            ) ?? name
        );
    };
    private handleSourceSetElementBookmark = (
        sourceSetElements: { [sourceSetElementId: string]: IReportSet },
        id: string,
        dateFormat: string
    ) => {
        const header = sourceSetElements[id]?._meta?.header;
        return header
            ? `(${TranslationHelper.translate(
                  header.objectName || ''
              )}) ${TranslationHelper.translate(
                  header.name || '',
                  'et'
              )} ${TimeFormatter.dateToString(header.date || '', dateFormat)}`
            : '';
    };
}

export default compose(
    withWidth(),
    withRouter,
    Themable,
    Connectable
)(Bookmarks) as ComponentType<{}>;
