export interface IColor {
    color: string;
    rgb: { r: number; g: number; b: number };
}
const COLORS: IColor[] = [
    { color: '#e53935', rgb: { r: 229, g: 57, b: 53 } },
    { color: '#3949AB', rgb: { r: 57, g: 73, b: 171 } },
    { color: '#7CB342', rgb: { r: 124, g: 179, b: 66 } },
    { color: '#FDD835', rgb: { r: 253, g: 216, b: 53 } },
    { color: '#FB8C00', rgb: { r: 251, g: 140, b: 0 } },
    { color: '#8E24AA', rgb: { r: 142, g: 36, b: 170 } },
    { color: '#039BE5', rgb: { r: 3, g: 155, b: 229 } },
    { color: '#6D4C41', rgb: { r: 109, g: 76, b: 65 } },
    { color: '#00897B', rgb: { r: 0, g: 137, b: 123 } },
    { color: '#546E7A', rgb: { r: 84, g: 110, b: 122 } },
];
const GREY: IColor = {
    color: '#808080',
    rgb: { r: 128, g: 128, b: 128 },
};
interface OrderedColor {
    index: number;
    color: IColor;
}
export class ColorPool {
    private availablePool: OrderedColor[];
    private assignedColors: { [key: string]: OrderedColor };

    constructor() {
        this.availablePool = [];
        this.assignedColors = {};
        this.fillPool();
    }

    private fillPool(): void {
        this.availablePool = COLORS.map((color, index) => ({ color, index }));
    }

    public assignColor(id: string): IColor {
        if (!id) {
            return ColorPool.getDefaultColor();
        }
        if (!this.availablePool.length) {
            this.fillPool();
        }
        this.assignedColors[id] ??= this.fetchColor();
        return this.assignedColors[id].color;
    }

    public releaseColors(items: string[]): void {
        for (const key in this.assignedColors) {
            const attibute = items.find((attr) => String(attr) === key);
            if (!attibute) {
                this.returnColorToPoolIfNeeded(this.assignedColors[key]);
                delete this.assignedColors[key];
            }
        }
    }
    public static getDefaultColor(): IColor {
        return { ...GREY };
    }

    private fetchColor() {
        return (
            this.availablePool.shift() || {
                color: ColorPool.getDefaultColor(),
                index: 0,
            }
        );
    }

    private returnColorToPoolIfNeeded(color: OrderedColor) {
        for (let i = 0; i < this.availablePool.length; i++) {
            const isColorAlreadyInPool =
                color.index === this.availablePool[i].index;
            if (isColorAlreadyInPool) {
                return;
            }
            if (color.index < this.availablePool[i].index) {
                this.availablePool.splice(i, 0, color);
                return;
            }
        }
        this.availablePool.push(color);
    }
}
