import * as yup from 'yup';
import { get, filter, isEmpty, memoize } from 'lodash';
import moment, { Moment } from 'moment';

import TranslationHelper from '../helpers/TranslationHelper';
import { conditionTypes } from '../state/app/alerts';
import { STATUS_ARCHIVAL } from '../constants/dictionaries/CscClientsStatus';

const DEFAULT_STRING_MAX_LENGTH = 255;
const REQUIRED = 'Field is required';

declare module 'yup' {
    interface ArraySchema<T> {
        uniqueProperty(prop: string, message?: string): ArraySchema<T>;
    }
}

yup.addMethod(yup.array, 'uniqueProperty', function (propertyPath, message) {
    return this.test('unique', '', function (list) {
        const errors: yup.ValidationError[] = [];

        list?.forEach((item, index) => {
            const propertyValue = get(item, propertyPath);

            if (
                propertyValue &&
                filter(list, [propertyPath, propertyValue]).length > 1
            ) {
                errors.push(
                    this.createError({
                        path: `${this.path}[${index}].${propertyPath}`,
                        message,
                    })
                );
            }
        });

        if (!isEmpty(errors)) {
            throw new yup.ValidationError(errors);
        }

        return true;
    });
});
export const reportsSchema = yup.object().shape({
    reportType: yup.object().shape({
        templateId: yup
            .string()
            .required(TranslationHelper.translate(REQUIRED)),
    }),
    dateFrom: yup.mixed().when(['dateTo'], (dateTo: Moment) => {
        return yup.lazy((dateFrom) => {
            if (moment(dateFrom).isAfter(dateTo)) {
                return yup
                    .string()
                    .length(
                        1,
                        TranslationHelper.translate(
                            'Invalid hour range selected'
                        )
                    );
            }
            return yup
                .date()
                .nullable()
                .required(TranslationHelper.translate(REQUIRED));
        });
    }),
    dateTo: yup.date().required(TranslationHelper.translate(REQUIRED)),
    objects: yup
        .array()
        .min(1)
        .when('reportType.isLocationReport', (isLocationReport, schema) => {
            return isLocationReport
                ? schema.min(1, TranslationHelper.translate('Select location'))
                : schema.min(
                      1,
                      TranslationHelper.translate('At least 1 object required')
                  );
        }),
});

export const reportScheduleSchema = yup.object().shape({
    reportType: yup.object().shape({
        templateId: yup
            .string()
            .required(TranslationHelper.translate(REQUIRED)),
    }),
    dateFrom: yup.date().required(TranslationHelper.translate(REQUIRED)),

    dateTo: yup.mixed().when(['dateFrom'], (dateFrom: Moment) => {
        return yup.lazy((dateTo) => {
            if (!moment(dateTo).isAfter(dateFrom)) {
                return yup
                    .string()
                    .length(
                        1,
                        TranslationHelper.translate(
                            'Start date should be before end date'
                        )
                    );
            }
            return yup
                .date()
                .nullable()
                .required(TranslationHelper.translate(REQUIRED));
        });
    }),
    periodicity: yup.string().required(TranslationHelper.translate(REQUIRED)),
    timeZone: yup.object().shape({
        name: yup.string().required(TranslationHelper.translate(REQUIRED)),
    }),
    emailsField: yup
        .string()
        .max(
            DEFAULT_STRING_MAX_LENGTH,
            TranslationHelper.translate('E-mail is too long')
        )
        .email(TranslationHelper.translate('E-mail is invalid'))
        .test(
            'includes',
            TranslationHelper.translate('Duplicate e-mail'),
            function (value) {
                if (this.parent.emails) {
                    return !this.parent.emails.includes(value);
                }
                return false;
            }
        ),
    objects: yup
        .array()
        .min(1)
        .when('reportType.isLocationReport', (isLocationReport, schema) => {
            return isLocationReport
                ? schema.min(1, TranslationHelper.translate('Select location'))
                : schema.min(
                      1,
                      TranslationHelper.translate('At least 1 object required')
                  );
        }),
});

const minutesOfDay = (m: Moment) => {
    return m.minutes() + m.hours() * 60;
};

export const roleSchema = memoize(() =>
    yup.object().shape({
        name: yup
            .string()
            .trim()
            .required(TranslationHelper.translate(REQUIRED))
            .max(
                64,
                TranslationHelper.translate('Value in this field is too long')
            ),
        description: yup
            .string()
            .trim()
            .required(TranslationHelper.translate(REQUIRED))
            .max(
                512,
                TranslationHelper.translate('Value in this field is too long')
            ),
    })
);

export const taskSchema = memoize(() =>
    yup.object().shape({
        plannedOn: yup
            .string()
            .nullable()
            .when('noDate', {
                is: false,
                then: yup
                    .string()
                    .nullable()
                    .required(TranslationHelper.translate(REQUIRED)),
            }),
        plannedTimeFrom: yup
            .mixed()
            .when(['plannedTimeTo'], (plannedTimeTo: Moment) => {
                return yup.lazy((plannedTimeFrom: Moment) => {
                    if (!moment(plannedTimeFrom).isValid()) {
                        return yup
                            .string()
                            .nullable()
                            .length(
                                1,
                                TranslationHelper.translate(
                                    'Invalid date format'
                                )
                            );
                    }
                    const begin = minutesOfDay(moment(plannedTimeFrom));
                    const end = minutesOfDay(moment(plannedTimeTo));

                    if (plannedTimeFrom && plannedTimeTo && begin > end) {
                        return yup
                            .string()
                            .length(
                                1,
                                TranslationHelper.translate(
                                    'Time from should be before time to'
                                )
                            );
                    }

                    return yup.string().nullable();
                });
            }),
        plannedTimeTo: yup.lazy((plannedTimeTo: Moment) => {
            if (!moment(plannedTimeTo).isValid()) {
                return yup
                    .string()
                    .nullable()
                    .length(
                        1,
                        TranslationHelper.translate('Invalid date format')
                    );
            }
            return yup.string().nullable();
        }),

        location: yup.object().shape({
            id: yup.number().required(TranslationHelper.translate(REQUIRED)),
        }),
        status: yup
            .number()
            .required(TranslationHelper.translate(REQUIRED))
            .moreThan(-1, TranslationHelper.translate(REQUIRED)),
        activityCategory: yup
            .number()
            .required(TranslationHelper.translate(REQUIRED))
            .moreThan(-1, TranslationHelper.translate(REQUIRED)),
        serviceType: yup
            .number()
            .required(TranslationHelper.translate(REQUIRED))
            .moreThan(-1, TranslationHelper.translate(REQUIRED)),
        objectCategory: yup
            .number()
            .required(TranslationHelper.translate(REQUIRED))
            .moreThan(-1, TranslationHelper.translate(REQUIRED)),
        notice: yup
            .string()
            .max(
                DEFAULT_STRING_MAX_LENGTH,
                TranslationHelper.translate('Value in this field is too long')
            ),
        attachments: yup.object().shape({
            errors: yup.boolean().oneOf([false], 'Test'),
        }),
        unitsCount: yup
            .number()
            .min(0, `${TranslationHelper.translate('Minimum value is')} 0`)
            .max(
                999999999,
                `${TranslationHelper.translate('Maximum value is')} 999999999`
            ),
        externalLinkages: yup
            .array()
            .of(
                yup.object().shape({
                    appName: yup
                        .string()
                        .required(TranslationHelper.translate(REQUIRED)),
                    externalId: yup
                        .string()
                        .trim()
                        .max(
                            50,
                            TranslationHelper.translate(
                                'Value in this field is too long'
                            )
                        )
                        .required(TranslationHelper.translate(REQUIRED)),
                })
            )
            .uniqueProperty(
                'externalId',
                TranslationHelper.translate('Identificator must be unique')
            ),
    })
);

export const taskLinkageSchema = memoize(() =>
    yup.object().shape({
        appName: yup.string().required(TranslationHelper.translate(REQUIRED)),
        externalId: yup
            .string()
            .trim()
            .max(
                DEFAULT_STRING_MAX_LENGTH,
                TranslationHelper.translate('Value in this field is too long')
            )
            .required(TranslationHelper.translate(REQUIRED)),
    })
);

const paramAlertSchema = () => {
    return {
        paramName: yup.string().when('type', (type: string): any => {
            if (Number(type) === conditionTypes.param) {
                return yup
                    .string()
                    .required(TranslationHelper.translate(REQUIRED));
            }
        }),
        definitionId: yup
            .string()
            .when(
                ['type', 'paramName'],
                (type: string, paramName: any): any => {
                    if (
                        Number(type) === conditionTypes.param &&
                        paramName.length > 0
                    ) {
                        return yup
                            .string()
                            .required(TranslationHelper.translate(REQUIRED));
                    }
                }
            ),
        paramValue: yup
            .mixed()
            .when(
                ['type', 'definitionId'],
                (type: string, definitionId: any): any => {
                    if (
                        Number(type) === conditionTypes.param &&
                        definitionId.length > 0
                    ) {
                        return yup.lazy((value) => {
                            switch (typeof value) {
                                case 'number':
                                    return yup
                                        .number()
                                        .required(
                                            TranslationHelper.translate(
                                                REQUIRED
                                            )
                                        )
                                        .min(
                                            -9999999,
                                            `${TranslationHelper.translate(
                                                'Minimum value is'
                                            )} -9999999`
                                        )
                                        .max(
                                            9999999,
                                            `${TranslationHelper.translate(
                                                'Maximum value is'
                                            )} 9999999`
                                        );
                                case 'string':
                                    return yup
                                        .string()
                                        .trim()
                                        .max(
                                            DEFAULT_STRING_MAX_LENGTH,
                                            `${TranslationHelper.translate(
                                                'The maximum number of characters in this field is'
                                            )} ${DEFAULT_STRING_MAX_LENGTH}`
                                        )
                                        .required(
                                            TranslationHelper.translate(
                                                REQUIRED
                                            )
                                        );
                                case 'boolean':
                                    return yup
                                        .boolean()
                                        .required(
                                            TranslationHelper.translate(
                                                REQUIRED
                                            )
                                        );
                                default:
                                    return yup.mixed();
                            }
                        });
                    }
                }
            ),
    };
};

const layerAlertSchema = () => {
    const requiredField = (type: string) => {
        if (Number(type) === conditionTypes.layer) {
            return yup.string().required(TranslationHelper.translate(REQUIRED));
        } else {
            return;
        }
    };
    return {
        definitionIdLayer: yup.string().when('type', (type: string): any => {
            return requiredField(type);
        }),
        layerId: yup.string().when('type', (type: string): any => {
            return requiredField(type);
        }),
        features: yup.array().of(
            yup.object().shape({
                name: yup
                    .string()
                    .trim()
                    .required(TranslationHelper.translate(REQUIRED)),
                id: yup
                    .string()
                    .trim()
                    .required(TranslationHelper.translate(REQUIRED)),
            })
        ),
    };
};

const eventAlertSchema = () => {
    return {
        eventType: yup.object().when('type', (type: string): any => {
            if (Number(type) === conditionTypes.event) {
                return yup.object().shape({
                    id: yup.lazy((value) => {
                        switch (typeof value) {
                            case 'number':
                                return yup
                                    .number()
                                    .required(
                                        TranslationHelper.translate(REQUIRED)
                                    );
                            case 'string':
                                return yup
                                    .string()
                                    .required(
                                        TranslationHelper.translate(REQUIRED)
                                    );
                            default:
                                return yup.mixed();
                        }
                    }),
                });
            }
        }),
    };
};
const paramAndEventAlertSchema = () => {
    const paramAlert = paramAlertSchema();
    const eventAlert = eventAlertSchema();
    const layerAlert = layerAlertSchema();
    return { ...paramAlert, ...eventAlert, ...layerAlert };
};

export const conditionsSchema = () => {
    return yup.object().when('type', (type: string): any => {
        if (
            Number(type) === conditionTypes.and ||
            Number(type) === conditionTypes.or
        ) {
            return yup.array().of(conditionSchema);
        }
    });
};
const conditionSchema = yup.object().shape({
    ...paramAndEventAlertSchema(),
    type: yup.string().trim().required(TranslationHelper.translate(REQUIRED)),
    conditions: conditionsSchema(),
});

export const alertSchema = memoize(() =>
    yup.object().shape({
        name: yup
            .string()
            .trim()
            .required(TranslationHelper.translate(REQUIRED))
            .max(
                DEFAULT_STRING_MAX_LENGTH,
                `${TranslationHelper.translate(
                    'The maximum number of characters in this field is'
                )} ${DEFAULT_STRING_MAX_LENGTH}`
            ),
        emailsField: yup
            .string()
            .max(
                DEFAULT_STRING_MAX_LENGTH,
                TranslationHelper.translate('E-mail is too long')
            )
            .email(TranslationHelper.translate('E-mail is invalid'))
            .test(
                'includes',
                TranslationHelper.translate('Duplicate e-mail'),
                function (value) {
                    return !this.parent.recipients.emails.includes(value);
                }
            ),
        phonesField: yup
            .string()
            .matches(
                /^(\+?[0-9]{1,3})?[0-9]{9}$/,
                TranslationHelper.translate('Phone number is invalid')
            )
            .test(
                'includes',
                TranslationHelper.translate('Duplicate phone number'),
                function (value) {
                    return !this.parent.recipients.phones.includes(value);
                }
            ),
        object: yup
            .string()
            .when('alertSubject', (alertSubject: string): any => {
                if (alertSubject === 'object') {
                    return yup
                        .object()
                        .required(TranslationHelper.translate(REQUIRED));
                } else {
                    return;
                }
            }),
        groups: yup
            .array()
            .when('alertSubject', (alertSubject: string): any => {
                if (alertSubject === 'group') {
                    return yup
                        .array()
                        .min(
                            1,
                            TranslationHelper.translate(
                                'At least 1 group required'
                            )
                        );
                } else {
                    return;
                }
            }),
        condition: conditionSchema,
    })
);

export const calibrationSchema = memoize(() =>
    yup.object().shape({
        from: yup.string().required(TranslationHelper.translate(REQUIRED)),
        params: yup.object().shape({
            paramName: yup
                .string()
                .required(TranslationHelper.translate(REQUIRED)),
            referentialFrom: yup
                .string()
                .required(TranslationHelper.translate('Date is required')),
            referentialValues: yup
                .array()
                .min(
                    2,
                    TranslationHelper.translate('At least 2 rows required')
                ),
        }),
    })
);

export const refuellAndLossConfigurationSchema = memoize(() =>
    yup.object().shape({
        params: yup.object().shape({
            referentialFrom: yup
                .string()
                .required(TranslationHelper.translate('Date is required')),
        }),
        detector: yup.object().shape({
            levelThreshold: yup
                .number()
                .typeError(
                    TranslationHelper.translate(
                        'Field must contain a numeric value'
                    )
                )
                .required(TranslationHelper.translate(REQUIRED))
                .min(
                    0.1,
                    `${TranslationHelper.translate('Minimum value is')} 0,1`
                )
                .max(
                    999999.9,
                    `${TranslationHelper.translate(
                        'Maximum value is'
                    )} 999999,9`
                ),
            refuelingThreshold: yup
                .number()
                .typeError(
                    TranslationHelper.translate(
                        'Field must contain a numeric value'
                    )
                )
                .required(TranslationHelper.translate(REQUIRED))
                .min(
                    0.1,
                    `${TranslationHelper.translate('Minimum value is')} 0,1`
                )
                .max(
                    999999.9,
                    `${TranslationHelper.translate(
                        'Maximum value is'
                    )} 999999,9`
                ),
            lossThreshold: yup
                .number()
                .typeError(
                    TranslationHelper.translate(
                        'Field must contain a numeric value'
                    )
                )
                .required(TranslationHelper.translate(REQUIRED))
                .min(
                    0.1,
                    `${TranslationHelper.translate('Minimum value is')} 0,1`
                )
                .max(
                    999999.9,
                    `${TranslationHelper.translate(
                        'Maximum value is'
                    )} 999999,9`
                ),
            samplesNumber: yup
                .number()
                .typeError(
                    TranslationHelper.translate(
                        'Field must contain a numeric value'
                    )
                )
                .required(TranslationHelper.translate(REQUIRED))
                .min(5, `${TranslationHelper.translate('Minimum value is')} 5`)
                .max(
                    999999,
                    `${TranslationHelper.translate('Maximum value is')} 999999`
                ),
        }),
        simplify: yup.object().shape({
            enabled: yup
                .boolean()
                .required(TranslationHelper.translate(REQUIRED)),
            tolerance: yup
                .number()
                .typeError(
                    TranslationHelper.translate(
                        'Field must contain a numeric value'
                    )
                )
                .required(TranslationHelper.translate(REQUIRED))
                .min(
                    0.1,
                    `${TranslationHelper.translate('Minimum value is')} 0,1`
                )
                .max(
                    999999.9,
                    `${TranslationHelper.translate(
                        'Maximum value is'
                    )} 999999,9`
                ),
            highQuality: yup
                .boolean()
                .required(TranslationHelper.translate(REQUIRED)),
        }),
        smooth: yup.object().shape({
            enabled: yup
                .boolean()
                .required(TranslationHelper.translate(REQUIRED)),
            bandwidth: yup
                .number()
                .typeError(
                    TranslationHelper.translate(
                        'Field must contain a numeric value'
                    )
                )
                .required(TranslationHelper.translate(REQUIRED))
                .min(
                    0.1,
                    `${TranslationHelper.translate('Minimum value is')} 0,1`
                )
                .max(
                    999999.9,
                    `${TranslationHelper.translate(
                        'Maximum value is'
                    )} 999999,9`
                ),
        }),
    })
);

export const editEventSchema = memoize(() =>
    yup.lazy((value) => {
        const key = Object.keys(value)[0];
        if (key === 'ODOMETER') {
            return yup.object().shape({
                ODOMETER: yup
                    .number()
                    .required(TranslationHelper.translate(REQUIRED))
                    .min(
                        0,
                        `${TranslationHelper.translate('Minimum value is')} 0`
                    )
                    .max(
                        2000000000,
                        `${TranslationHelper.translate(
                            'Maximum value is'
                        )} 2000000000`
                    ),
            });
        } else {
            return yup.object().shape({
                REFUEL: yup
                    .number()
                    .required(TranslationHelper.translate(REQUIRED))
                    .min(
                        0,
                        `${TranslationHelper.translate('Minimum value is')} 0`
                    )
                    .max(
                        999999,
                        `${TranslationHelper.translate(
                            'Maximum value is'
                        )} 999999`
                    ),
            });
        }
    })
);

export const groupSchema = memoize(() =>
    yup.object().shape({
        name: yup
            .string()
            .trim()
            .required(TranslationHelper.translate(REQUIRED))
            .max(
                DEFAULT_STRING_MAX_LENGTH,
                `${TranslationHelper.translate(
                    'The maximum number of characters in this field is'
                )} ${DEFAULT_STRING_MAX_LENGTH}`
            )
            .matches(
                /^[^.$]*$/,
                TranslationHelper.translate(
                    'Group name cannot contain "." or "$" signs'
                )
            ),

        number: yup
            .number()
            .min(0, `${TranslationHelper.translate('Minimum value is')} 0`)
            .max(
                2147483647,
                `${TranslationHelper.translate('Maximum value is')} 2147483647`
            ),
    })
);

const password = () =>
    yup
        .string()
        .required(TranslationHelper.translate('Password is a required field'))
        .max(
            50,
            TranslationHelper.translate(
                'Password cannot be longer than 50 characters'
            )
        )
        .matches(
            /^\S*$/,
            TranslationHelper.translate(`Password cannot contain blank spaces`)
        )
        .matches(
            /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])[A-Za-z0-9!"#$%&'()*+,\-./:;<=>?@\[\]^_`{|}~\\]{8,50}$/,
            TranslationHelper.translate(
                `Make sure it's at least 8 characters including a number, a lowercase and uppercase letter`
            )
        );

const passwordRepeat = () =>
    yup
        .string()
        .required(
            TranslationHelper.translate(
                'Confirm new password is a required field'
            )
        )
        .oneOf(
            [yup.ref('password'), null],
            TranslationHelper.translate('Passwords do not match')
        );

const emailRepeat = () =>
    yup
        .string()
        .required(
            TranslationHelper.translate('Confirm e-mail is a required field')
        )
        .oneOf(
            [yup.ref('email'), null],
            TranslationHelper.translate('Email addresses do not match')
        );
const sharedShape = () => ({
    name: yup
        .string()
        .trim()
        .required(TranslationHelper.translate('First name is required'))
        .max(
            20,
            TranslationHelper.translate(
                'Name cannot be longer than 20 characters'
            )
        ),
    surname: yup
        .string()
        .trim()
        .required(TranslationHelper.translate('Surname is required'))
        .max(
            40,
            TranslationHelper.translate(
                'Surname cannot be longer than 40 characters'
            )
        ),
    email: yup
        .string()
        .required(TranslationHelper.translate('E-mail is required'))
        .email(TranslationHelper.translate('E-mail is invalid')),
});

export const userCreationSchema = memoize(() =>
    yup.object().shape({
        login: yup
            .string()
            .required(TranslationHelper.translate('Login is required'))
            .email(TranslationHelper.translate('Login is invalid')),
        ...sharedShape(),
    })
);

export const userEditionSchema = memoize(() =>
    yup.object().shape({
        ...sharedShape(),
    })
);

export const passwordSchema = memoize(() =>
    yup.object().shape({
        password: password(),
        passwordRepeat: passwordRepeat(),
    })
);

export const loginMigrationSchema = memoize(() =>
    yup.object().shape({
        email: yup
            .string()
            .required(TranslationHelper.translate('E-mail is required'))
            .email(TranslationHelper.translate('E-mail is invalid')),
        confirmEmail: emailRepeat(),
    })
);
export const passwordResetMailSchema = memoize(() =>
    yup.object().shape({
        tenant: yup
            .number()
            .required(TranslationHelper.translate(REQUIRED))
            .moreThan(-1, TranslationHelper.translate(REQUIRED)),
        login: yup
            .string()
            .matches(
                /^[a-zA-Z0-9@._-]*$/,
                TranslationHelper.translate(
                    'Valid characters are A-Z a-z 0-9 @ . _ -'
                )
            )
            .max(
                DEFAULT_STRING_MAX_LENGTH,
                TranslationHelper.translate('Value in this field is too long')
            ),
        email: yup.string(),
        loginOrEmail: yup.bool().when(['login', 'email'], {
            is: (login: string, email: string) => !login && !email,
            then: yup
                .bool()
                .required(
                    TranslationHelper.translate('Login or e-mail required')
                ),
            otherwise: yup.bool(),
        }),
    })
);

export const passwordChangeSchema = memoize(() =>
    yup.object().shape({
        oldPassword: yup
            .string()
            .required(
                TranslationHelper.translate(
                    'Current password is a required field'
                )
            ),
        newPassword: password().notOneOf(
            [yup.ref('oldPassword'), null],
            TranslationHelper.translate(
                'New password and old password must not match'
            )
        ),
        confirmNewPassword: yup
            .string()
            .required(
                TranslationHelper.translate(
                    'Confirm new password is a required field'
                )
            )
            .oneOf(
                [yup.ref('newPassword'), null],
                TranslationHelper.translate(
                    'New password and confirm new password must match'
                )
            ),
    })
);

export const registerRefuelingSchema = memoize(() =>
    yup.object().shape({
        refuelDate: yup
            .string()
            .required(TranslationHelper.translate(REQUIRED)),
        refuelValue: yup
            .number()
            .required(TranslationHelper.translate(REQUIRED))
            .min(0, `${TranslationHelper.translate('Minimum value is')} 0`)
            .max(
                2000000000,
                `${TranslationHelper.translate('Maximum value is')} 2000000000`
            ),
        odometer: yup
            .number()
            .required(TranslationHelper.translate(REQUIRED))
            .min(0, `${TranslationHelper.translate('Minimum value is')} 0`)
            .max(
                2000000000,
                `${TranslationHelper.translate('Maximum value is')} 2000000000`
            ),
        employeeOrVehicle: yup.bool().when(['vehicle', 'employee'], {
            is: (vehicle: any, employee: any) => !vehicle.id && !employee.id,
            then: yup
                .bool()
                .required(
                    TranslationHelper.translate('Employee or vehicle required')
                ),
            otherwise: yup.bool(),
        }),
        notice: yup
            .string()
            .max(
                500,
                TranslationHelper.translate('Value in this field is too long')
            ),
    })
);

export const rfidImportSchema = memoize(() =>
    yup.object().shape({
        file: yup
            .mixed()
            .required(TranslationHelper.translate('File is required'))
            .test(
                'type',
                `${TranslationHelper.translate(
                    'Only the following formats are accepted'
                )}: .xlsx`,
                (value) => {
                    return (
                        value &&
                        value.type ===
                            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                    );
                }
            ),
        rfidGroupId: yup
            .string()
            .required(TranslationHelper.translate(REQUIRED)),
        createVehicles: yup.bool(),
        vehicleGroupId: yup.string().when('createVehicles', {
            is: true,
            then: yup.string().required(TranslationHelper.translate(REQUIRED)),
        }),
        createEmployees: yup.bool(),
        employeeGroupId: yup.string().when('createEmployees', {
            is: true,
            then: yup.string().required(TranslationHelper.translate(REQUIRED)),
        }),
        timeZone: yup.string().when(['createEmployees', 'createVehicles'], {
            is: (vehicle: any, employee: any) => vehicle || employee,
            then: yup.string().required(TranslationHelper.translate(REQUIRED)),
        }),
    })
);

export const vehicleEditSchema = memoize(() => {
    const year = new Date().getFullYear();
    return yup.object().shape({
        name: yup
            .string()
            .trim()
            .required(TranslationHelper.translate(REQUIRED))
            .max(
                DEFAULT_STRING_MAX_LENGTH,
                TranslationHelper.translate('Value in this field is too long')
            ),
        groups: yup.array().min(1, TranslationHelper.translate(REQUIRED)),
        plateNumber: yup
            .string()
            .trim()
            .max(
                DEFAULT_STRING_MAX_LENGTH,
                TranslationHelper.translate('Value in this field is too long')
            ),
        sideNumber: yup
            .string()
            .trim()
            .max(
                DEFAULT_STRING_MAX_LENGTH,
                TranslationHelper.translate('Value in this field is too long')
            ),
        timeZone: yup
            .object()
            .nullable()
            .required(TranslationHelper.translate(REQUIRED)),
        mark: yup
            .string()
            .trim()
            .max(
                DEFAULT_STRING_MAX_LENGTH,
                TranslationHelper.translate('Value in this field is too long')
            ),
        model: yup
            .string()
            .trim()
            .max(
                DEFAULT_STRING_MAX_LENGTH,
                TranslationHelper.translate('Value in this field is too long')
            ),
        vin: yup
            .string()
            .trim()
            .max(
                DEFAULT_STRING_MAX_LENGTH,
                TranslationHelper.translate('Value in this field is too long')
            ),
        productionYear: yup
            .number()
            .min(
                1900,
                `${TranslationHelper.translate('Minimum value is')} 1900`
            )
            .max(
                year,
                `${TranslationHelper.translate('Maximum value is')} ${year}`
            ),
        fuelConsumption: yup
            .number()
            .min(0, `${TranslationHelper.translate('Minimum value is')} 0`)
            .max(
                99999999999999,
                `${TranslationHelper.translate(
                    'Maximum value is'
                )} 99999999999999`
            ),
        fuelConsumptionWork: yup
            .number()
            .min(0, `${TranslationHelper.translate('Minimum value is')} 0`)
            .max(
                99999999999999,
                `${TranslationHelper.translate(
                    'Maximum value is'
                )} 99999999999999`
            ),
        tankSize: yup
            .number()
            .min(0, `${TranslationHelper.translate('Minimum value is')} 0`)
            .max(
                99999999999999,
                `${TranslationHelper.translate(
                    'Maximum value is'
                )} 99999999999999`
            ),
    });
});

export const employeeEditSchema = memoize(() =>
    yup.object().shape({
        name: yup
            .string()
            .trim()
            .required(TranslationHelper.translate(REQUIRED))
            .max(
                50,
                TranslationHelper.translate('Value in this field is too long')
            ),
        groups: yup.array().min(1, TranslationHelper.translate(REQUIRED)),

        firstName: yup
            .string()
            .trim()
            .required(TranslationHelper.translate(REQUIRED))
            .max(
                DEFAULT_STRING_MAX_LENGTH,
                TranslationHelper.translate('Value in this field is too long')
            ),
        surname: yup
            .string()
            .trim()
            .required(TranslationHelper.translate(REQUIRED))
            .max(
                DEFAULT_STRING_MAX_LENGTH,
                TranslationHelper.translate('Value in this field is too long')
            ),
        timeZone: yup
            .object()
            .nullable()
            .required(TranslationHelper.translate(REQUIRED)),
        contact: yup.object().shape({
            email: yup
                .string()
                .trim()
                .email(TranslationHelper.translate('E-mail is invalid')),
            phoneNumber: yup
                .string()
                .trim()
                .max(
                    DEFAULT_STRING_MAX_LENGTH,
                    TranslationHelper.translate(
                        'Value in this field is too long'
                    )
                )
                .matches(
                    /^(\d|\+|-|\ )+$/,
                    TranslationHelper.translate('Phone number is invalid')
                ),
            mobileNumber: yup
                .string()
                .trim()
                .max(
                    DEFAULT_STRING_MAX_LENGTH,
                    TranslationHelper.translate(
                        'Value in this field is too long'
                    )
                )
                .matches(
                    /^(\d|\+|-|\ )+$/,
                    TranslationHelper.translate('Mobile number is invalid')
                ),
        }),
        address: yup.object().shape({
            street: yup
                .string()
                .trim()
                .max(
                    DEFAULT_STRING_MAX_LENGTH,
                    TranslationHelper.translate(
                        'Value in this field is too long'
                    )
                ),
            houseNumber: yup
                .string()
                .trim()
                .max(
                    50,
                    TranslationHelper.translate(
                        'Value in this field is too long'
                    )
                ),
            apartmentNumber: yup
                .string()
                .trim()
                .max(
                    50,
                    TranslationHelper.translate(
                        'Value in this field is too long'
                    )
                ),
            postalCode: yup
                .string()
                .max(
                    10,
                    TranslationHelper.translate(
                        'Value in this field is too long'
                    )
                ),
            city: yup
                .string()
                .trim()
                .max(
                    DEFAULT_STRING_MAX_LENGTH,
                    TranslationHelper.translate(
                        'Value in this field is too long'
                    )
                ),
        }),
    })
);

export const tankEditSchema = memoize(() =>
    yup.object().shape({
        name: yup
            .string()
            .trim()
            .required(TranslationHelper.translate(REQUIRED))
            .max(
                50,
                TranslationHelper.translate('Value in this field is too long')
            ),
        groups: yup.array(),
        capacity: yup
            .number()
            .min(0, `${TranslationHelper.translate('Minimum value is')} 0`)
            .max(
                99999999999999,
                `${TranslationHelper.translate(
                    'Maximum value is'
                )} 99999999999999`
            ),
        reserve: yup
            .number()
            .min(0, `${TranslationHelper.translate('Minimum value is')} 0`)
            .max(
                99999999999999,
                `${TranslationHelper.translate(
                    'Maximum value is'
                )} 99999999999999`
            ),
        timeZone: yup
            .object()
            .nullable()
            .required(TranslationHelper.translate(REQUIRED)),
        logicType: yup.number().required(TranslationHelper.translate(REQUIRED)),
        fuelType: yup
            .string()
            .trim()
            .max(
                DEFAULT_STRING_MAX_LENGTH,
                TranslationHelper.translate('Value in this field is too long')
            ),
        description: yup
            .string()
            .trim()
            .max(
                DEFAULT_STRING_MAX_LENGTH,
                TranslationHelper.translate('Value in this field is too long')
            ),
    })
);

const basicLocationSchema = () => {
    return {
        name: yup
            .string()
            .trim()
            .required(TranslationHelper.translate(REQUIRED))
            .max(
                244,
                TranslationHelper.translate('Value in this field is too long')
            ),
        longitude: yup
            .number()
            .required(TranslationHelper.translate(REQUIRED))
            .min(
                -179,
                `${TranslationHelper.translate('Minimum value is')} -179`
            )
            .max(179, `${TranslationHelper.translate('Maximum value is')} 179`),
        latitude: yup
            .number()
            .required(TranslationHelper.translate(REQUIRED))
            .min(-89, `${TranslationHelper.translate('Minimum value is')} -89`)
            .max(89, `${TranslationHelper.translate('Maximum value is')} 89`),
        groups: yup
            .mixed()
            .when('visibilityType', (visibilityType: string): any => {
                if (visibilityType === 'group') {
                    return yup
                        .array()
                        .min(
                            1,
                            TranslationHelper.translate(
                                'At least 1 group required'
                            )
                        );
                } else {
                    return;
                }
            }),
        notes: yup
            .string()
            .trim()
            .max(
                DEFAULT_STRING_MAX_LENGTH,
                TranslationHelper.translate('Value in this field is too long')
            ),
    };
};

export const editLocationSchema = memoize(() =>
    yup.object().shape({
        ...basicLocationSchema(),
    })
);

export const addLocationSchema = memoize(() =>
    yup.object().shape({
        ...basicLocationSchema(),
    })
);

const basicClientSchema = () => {
    return {
        name: yup
            .string()
            .trim()
            .required(TranslationHelper.translate(REQUIRED))
            .max(
                DEFAULT_STRING_MAX_LENGTH,
                TranslationHelper.translate('Value in this field is too long')
            ),
        shortName: yup
            .string()
            .trim()
            .max(
                DEFAULT_STRING_MAX_LENGTH,
                TranslationHelper.translate('Value in this field is too long')
            ),
        externalNumber: yup
            .string()
            .trim()
            .max(
                DEFAULT_STRING_MAX_LENGTH,
                TranslationHelper.translate('Value in this field is too long')
            ),
        vatin: yup
            .string()
            .trim()
            .max(
                DEFAULT_STRING_MAX_LENGTH,
                TranslationHelper.translate('Value in this field is too long')
            ),
        notice: yup
            .string()
            .trim()
            .max(
                DEFAULT_STRING_MAX_LENGTH,
                TranslationHelper.translate('Value in this field is too long')
            ),
    };
};

export const cscAddClientSchema = memoize(() =>
    yup.object().shape({ ...basicClientSchema() })
);

export const cscEditClientSchema = memoize(() =>
    yup.object().shape({
        ...basicClientSchema(),
        status: yup
            .number()
            .required(TranslationHelper.translate(REQUIRED))
            .moreThan(-1, TranslationHelper.translate(REQUIRED)),
        archivalDate: yup
            .string()
            .nullable()
            .when('status', {
                is: STATUS_ARCHIVAL,
                then: yup
                    .string()
                    .nullable()
                    .required(TranslationHelper.translate(REQUIRED)),
            }),
    })
);

const basicCscLocationSchema = () => {
    return {
        name: yup
            .string()
            .trim()
            .required(TranslationHelper.translate(REQUIRED))
            .max(
                DEFAULT_STRING_MAX_LENGTH,
                TranslationHelper.translate('Value in this field is too long')
            ),
        address: yup.object().shape({
            street: yup
                .string()
                .trim()
                .max(
                    DEFAULT_STRING_MAX_LENGTH,
                    TranslationHelper.translate(
                        'Value in this field is too long'
                    )
                ),
            streetNumber: yup
                .string()
                .trim()
                .max(
                    50,
                    TranslationHelper.translate(
                        'Value in this field is too long'
                    )
                ),
            apartmentNumber: yup
                .string()
                .trim()
                .max(
                    50,
                    TranslationHelper.translate(
                        'Value in this field is too long'
                    )
                ),
            zip: yup
                .string()
                .max(
                    10,
                    TranslationHelper.translate(
                        'Value in this field is too long'
                    )
                ),
            city: yup
                .string()
                .trim()
                .required(TranslationHelper.translate(REQUIRED))
                .max(
                    DEFAULT_STRING_MAX_LENGTH,
                    TranslationHelper.translate(
                        'Value in this field is too long'
                    )
                ),
        }),
        coordinate: yup.object().shape({
            x: yup
                .number()
                .transform((value) => (Number.isNaN(value) ? null : value))
                .nullable()
                .required(TranslationHelper.translate(REQUIRED))
                .min(
                    -179,
                    `${TranslationHelper.translate('Minimum value is')} -179`
                )
                .max(
                    179,
                    `${TranslationHelper.translate('Maximum value is')} 179`
                ),
            y: yup
                .number()
                .transform((value) => (Number.isNaN(value) ? null : value))
                .nullable()
                .required(TranslationHelper.translate(REQUIRED))
                .min(
                    -89,
                    `${TranslationHelper.translate('Minimum value is')} -89`
                )
                .max(
                    89,
                    `${TranslationHelper.translate('Maximum value is')} 89`
                ),
        }),
        externalNo: yup
            .string()
            .trim()
            .required(TranslationHelper.translate(REQUIRED))
            .max(
                DEFAULT_STRING_MAX_LENGTH,
                TranslationHelper.translate('Value in this field is too long')
            ),
        notes: yup
            .string()
            .trim()
            .max(
                DEFAULT_STRING_MAX_LENGTH,
                TranslationHelper.translate('Value in this field is too long')
            ),
        groups: yup
            .mixed()
            .when('visibilityType', (visibilityType: string): any => {
                if (visibilityType === 'group') {
                    return yup
                        .array()
                        .min(
                            1,
                            TranslationHelper.translate(
                                'At least 1 group required'
                            )
                        );
                } else {
                    return;
                }
            }),
    };
};

export const cscAddLocationSchema = memoize(() =>
    yup.object().shape({
        ...basicCscLocationSchema(),
        client: yup
            .string()
            .trim()
            .required(TranslationHelper.translate(REQUIRED)),
    })
);

export const cscEditLocationSchema = memoize(() =>
    yup.object().shape({
        ...basicCscLocationSchema(),
    })
);

export const searchEventsSchema = memoize(() =>
    yup.object().shape({
        longitude: yup
            .number()
            .required(TranslationHelper.translate(REQUIRED))
            .min(
                -179,
                `${TranslationHelper.translate('Minimum value is')} -179`
            )
            .max(179, `${TranslationHelper.translate('Maximum value is')} 179`),
        latitude: yup
            .number()
            .required(TranslationHelper.translate(REQUIRED))
            .min(-89, `${TranslationHelper.translate('Minimum value is')} -89`)
            .max(89, `${TranslationHelper.translate('Maximum value is')} 89`),
    })
);
