import { connect, ConnectedProps } from 'react-redux';
import { TRootState } from '../../../../../../store';

import { closePreview } from '../../../../../../state/_actions';
import {
    getPreviewCreatorLevel,
    getPreviewLastState,
} from '../../../../selectors';

const mapStateToProps = (state: TRootState) => ({
    creatorLevel: getPreviewCreatorLevel(state),
    lastState: getPreviewLastState(state),
});

const mapDispatchToProps = {
    closePreview,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type TConnectableProps = ConnectedProps<typeof connector>;

export const Connectable = connector;
