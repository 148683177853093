import React, { Component } from 'react';

import { connect } from 'react-redux';

import MultipleSelect from 'react-select';

import { object } from 'prop-types';

import {
    getFuelTanksFilter,
    updateFuelTank,
} from '../../../../state/app/registers/tanks';
import { hideGlobalDialog } from '../../../../state/ui/dialog';

import { restGetTimezoneDictionary } from '../../../../services/registers';
import { restGetGroups } from '../../../../services/groups';

import {
    STANDARD_LOGIC,
    REVERSED_LOGIC,
} from '../../../../constants/dictionaries/TankLogic';

import Translator from '../../../../helpers/TranslationHelper';

import Dialog from '../../../dialog/Dialog';
import PrimaryButton from '../../../button/PrimaryButton';
import SecondaryButton from '../../../button/SecondaryButton';
import AsyncFilterSelect from '../../../AsyncFilterSelect';
import ValidationMessage from '../../../common/ValidationMessage';
import CustomAutocomplete from '../../../common/CustomAutocomplete';

const logicTypeValues = () => [
    {
        label: Translator.translate('Simple'),
        value: STANDARD_LOGIC,
        clearableValue: false,
    },
    {
        label: Translator.translate('Reversed'),
        value: REVERSED_LOGIC,
        clearableValue: false,
    },
];

class FuelTankDialog extends Component {
    state = {
        groups: this.props.tank.groups || [],
        timeZone: this.props.tank.timeZone,
        logicType: this.props.tank.logicType || 0,
        validationMessage: undefined,
        loading: false,
    };

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleCancel = (event) => {
        event.preventDefault();
        this.props.hideGlobalDialog();
    };

    _prepareTankData() {
        const { groups, logicType, timeZone } = this.state;

        return this.props.tank.merge({
            name: this.name.value || undefined,
            capacity: this.capacity.value || undefined,
            reserve: this.reserve.value || undefined,
            timeZone,
            fuelType: this.fuelType.value,
            logicType,
            groupIds: groups.map((group) => group.id),
            groups: undefined,
            description: this.description.value || undefined,
        });
    }

    handleSubmit = (event) => {
        event.preventDefault();

        const { filter, updateFuelTank, hideGlobalDialog } = this.props;

        this.setState({
            loading: true,
        });

        let data = this._prepareTankData();

        const successCallback = (validation) => {
            if (validation.success) {
                hideGlobalDialog();
            } else if (validation.messages && this._isMounted) {
                this.setState({
                    validationMessage: validation.messages[0],
                    loading: false,
                });
            }
        };
        const failCallback = () => {
            if (this._isMounted) {
                this.setState({
                    validationMessage: Translator.translate(
                        'Unrecognized error has occurred'
                    ),
                    loading: false,
                });
            }
        };
        updateFuelTank(
            data,
            filter,
            (validation) => successCallback(validation),
            () => failCallback()
        );
    };

    handleTimeZoneChange = (option) => {
        if (option) {
            this.setState({
                timeZone: {
                    id: option.id,
                    name: option.name,
                },
            });
        }
    };

    handleGroupChange = (e, groups) => {
        this.setState({ groups });
    };

    handleLogicTypeChange = (option) => {
        let logicType = option ? option.value : undefined;
        this.setState({ logicType });
    };

    formatInput = (ref) => {
        ref.value = ref.value.trim();
    };

    render() {
        const { groups, timeZone, logicType, validationMessage, loading } =
            this.state;
        const { tank } = this.props;

        return (
            <Dialog
                title={Translator.translate('Edit fuel tank')}
                onClose={this.handleCancel}
            >
                <form
                    className="form fuel-tank-form"
                    onSubmit={this.handleSubmit}
                    autoComplete="off"
                >
                    <div className="section">
                        <div className="form-group single">
                            <div className="form-title">
                                {Translator.translate('Name')}
                            </div>

                            <div className="form-input">
                                <input
                                    type="text"
                                    className="mtl-input"
                                    ref={(element) => (this.name = element)}
                                    required
                                    defaultValue={tank.name}
                                    placeholder={''}
                                    maxLength="255"
                                    onBlur={() => this.formatInput(this.name)}
                                />
                            </div>
                        </div>
                        <div className="form-group single">
                            <div className="form-title">
                                {Translator.translate('Group')}
                            </div>

                            <div className="form-input">
                                <CustomAutocomplete
                                    value={groups}
                                    inputTexts={{ placeholder: 'Select group' }}
                                    multi
                                    onChange={this.handleGroupChange}
                                    onOpen={restGetGroups}
                                    optionKeys={{ name: 'name', value: 'id' }}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="form-title">
                                {Translator.translate('Capacity')}
                            </div>
                            <div className="form-input">
                                <input
                                    type="number"
                                    className="mtl-input"
                                    ref={(element) => (this.capacity = element)}
                                    required
                                    defaultValue={tank.capacity || 0}
                                    placeholder={''}
                                    min="0"
                                    max="99999999999999"
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="form-title">
                                {Translator.translate('FuelTank_Reserve')}
                            </div>
                            <div className="form-input">
                                <input
                                    type="number"
                                    className="mtl-input"
                                    ref={(element) => (this.reserve = element)}
                                    required
                                    defaultValue={tank.reserve || 0}
                                    placeholder={''}
                                    min="0"
                                    max="99999999999999"
                                />
                            </div>
                        </div>
                        <div className="form-group single">
                            <div className="form-title">
                                {Translator.translate('Time zone')}
                            </div>
                            <div className="form-input">
                                <AsyncFilterSelect
                                    name="timeZone"
                                    selectedOptionId={timeZone && timeZone.id}
                                    getData={() => restGetTimezoneDictionary()}
                                    clearable={false}
                                    required
                                    value={timeZone}
                                    placeholder={Translator.translate(
                                        'Select time zone'
                                    )}
                                    onOptionSelect={this.handleTimeZoneChange}
                                />
                            </div>
                        </div>
                        <div className="form-group single">
                            <div className="form-title">
                                {Translator.translate('Logic')}
                            </div>

                            <div className="form-input">
                                <MultipleSelect
                                    name="type"
                                    clearable={false}
                                    required
                                    value={logicType}
                                    placeholder={Translator.translate(
                                        'Select logic type'
                                    )}
                                    options={logicTypeValues()}
                                    onChange={this.handleLogicTypeChange}
                                    noResultsText={Translator.translate(
                                        'No results found'
                                    )}
                                    clearAllText={Translator.translate(
                                        'Clear all'
                                    )}
                                />
                            </div>
                        </div>
                        <div className="form-group single">
                            <div className="form-title">
                                {Translator.translate('Content')}
                            </div>
                            <div className="form-input">
                                <input
                                    type="text"
                                    className="mtl-input"
                                    ref={(element) => (this.fuelType = element)}
                                    defaultValue={tank.fuelType}
                                    placeholder={''}
                                    maxLength="255"
                                />
                            </div>
                        </div>
                        <div className="form-group single">
                            <div className="form-title">
                                {Translator.translate('Description')}
                            </div>
                            <div className="form-input">
                                <input
                                    type="text"
                                    className="mtl-input"
                                    ref={(element) =>
                                        (this.description = element)
                                    }
                                    defaultValue={tank.description}
                                    placeholder={''}
                                    maxLength="255"
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <ValidationMessage
                                message={Translator.translate(
                                    validationMessage
                                )}
                                error={validationMessage !== undefined}
                            />
                        </div>
                    </div>
                    <div className="form-actions">
                        <PrimaryButton
                            icon="icon-floppy-disk"
                            label={Translator.translate('Save')}
                            disabled={loading}
                        />
                        <SecondaryButton
                            onClick={this.handleCancel}
                            icon="icon-cross"
                            label={Translator.translate('Cancel')}
                        />
                    </div>
                </form>
            </Dialog>
        );
    }
}

FuelTankDialog.propTypes = {
    tank: object,
};

const mapStateToProps = (state) => ({
    filter: getFuelTanksFilter(state),
});

const mapDispatchToProps = {
    updateFuelTank,
    hideGlobalDialog,
};

export default connect(mapStateToProps, mapDispatchToProps)(FuelTankDialog);
