import React, { Fragment } from 'react';
import { useMediaQuery } from '@material-ui/core';
import { default as IconButton } from '@material-ui/core/IconButton';
import { default as ChartIcon } from '@material-ui/icons/InsertChart';
import { default as MultiLineChartIcon } from '@material-ui/icons/MultilineChart';
import { default as GridIcon } from '@material-ui/icons/GridOn';

import { ISourceSetAction } from '../../../../state/ui/discovery/types';
import { IReport } from '../../../../state/types';
import { DESKTOP } from '../../../../constants/dictionaries/ScreenSizeConst';

interface IReportProps {
    report: IReport;
    handleOpenSourceSetClick: (action: ISourceSetAction | undefined) => void;
    handleOpenChartClick: () => void;
    handleOpenGridChartClick: () => void;
}

const ReportDetailsIcons: React.FC<IReportProps> = (props) => {
    const matches = useMediaQuery(DESKTOP);

    const {
        report,
        handleOpenChartClick,
        handleOpenGridChartClick,
        handleOpenSourceSetClick,
    } = props;

    const tankChartAction = report?._meta?.actions?.tankChart;
    const sourceSetAction = report?._meta?.actions?.sourceSet;

    return (
        <Fragment>
            {matches && tankChartAction && (
                <IconButton onClick={handleOpenChartClick}>
                    <ChartIcon />
                </IconButton>
            )}
            {matches &&
                sourceSetAction &&
                sourceSetAction?.params.reportId !== 'tasks' && (
                    <IconButton onClick={handleOpenGridChartClick}>
                        <MultiLineChartIcon />
                    </IconButton>
                )}
            {sourceSetAction && (
                <IconButton
                    onClick={() => handleOpenSourceSetClick(sourceSetAction)}
                >
                    <GridIcon />
                </IconButton>
            )}
        </Fragment>
    );
};

export default ReportDetailsIcons;
