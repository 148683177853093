import TimeFormatter from '../../../helpers/TimeFormatter';

export const makeSourceSetId = (params: {
    [key: string]: string | number | boolean;
}) => {
    if (params['_asUrl']) {
        return null;
    }
    const { monitoredId, reportId, from, to } = params;
    if (monitoredId && reportId && from && to) {
        return (
            monitoredId.toString() +
            reportId.toString() +
            from.toString() +
            to.toString()
        );
    } else {
        return null;
    }
};

// TODO remove this override when we start using real service - not static json
export const simulateProperDataForPresentation = (
    data: {
        monitoredId?: string | number;
        from?: string;
        to?: string;
        id?: string;
        _meta?: {
            header: { monitoredId: string | number };
            params: {
                monitoredId?: string | number;
                from?: string;
                to?: string;
            };
        };
    },
    monitoredId: number,
    from: string,
    to: string
) => {
    data.monitoredId = monitoredId.toString();
    data.from = TimeFormatter.getISOString(from);
    data.id = `?monitoredId=${data.monitoredId}&from=${data.from}&to`;

    if (data._meta) {
        data._meta = data._meta || {};
        data._meta.header.monitoredId = data.monitoredId;

        if (data._meta.params) {
            data.to = data._meta.params.to || TimeFormatter.getISOString(to);
        }
    }

    return data as {
        monitoredId: string;
        from: string;
        to: string;
        id: string;
        _meta?: {
            header: { monitoredId: string | number };
            params: {
                monitoredId?: string | number;
                from?: string;
                to?: string;
            };
        };
    };
};
