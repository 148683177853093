import {
    createSlice,
    createSelector,
    createAsyncThunk,
} from '@reduxjs/toolkit';

import { chunk } from 'lodash';

import { sortAlphabetically } from '../../../helpers/comparators';
import {
    IGetGroupEmptyAssignmentsParams,
    IRestPatchGroupAssignments,
    restGetGroupAssignments,
    restGetGroupEmptyAssignments,
    restPatchGroupAssignments,
} from '../../../services/groups';
import { TRootState } from '../../../store';
import {
    restGetEmptyLocationGroupAssignments,
    restGetLocationGroupAssignments,
    restPatchLocationGroupAssignments,
} from '../../../services/locations';
import { formatAddress } from '../../../helpers/formatAddress';

export interface IAssignmentsAccess {
    registers: boolean;
    locations: boolean;
}

export interface IGroupAssignment {
    registerId: number;
    registerType: string;
    type: string;
    name: string;
    relationId: string;
    address?: string;
}

interface IState {
    selectedGroupId: null | string;
    mode: null | 'preview' | 'edit' | 'create';
    emptyAssignments: IGroupAssignment[];
    emptyAssigmentsLoading: boolean;
    assignments: IGroupAssignment[];
}

const MAX_ITEM_LIMIT = 1000;

const initialState: IState = {
    selectedGroupId: null,
    mode: null,
    emptyAssignments: [],
    emptyAssigmentsLoading: false,
    assignments: [],
};

interface IGroupAssignmentCollection {
    withAddress: IGroupAssignment[];
    withoutAddress: IGroupAssignment[];
}

export const fetchGroupAssignments = createAsyncThunk(
    'get:ui/groups/assignments',
    async (params: { id: string; access: IAssignmentsAccess }) => {
        const { id, access } = params;
        const responses = await Promise.all([
            access.registers
                ? restGetGroupAssignments(id)
                : Promise.resolve([]),
            access.locations
                ? restGetLocationGroupAssignments(id)
                : Promise.resolve([]),
        ]);
        const locationsAsGroupAssignments = responses?.[1]?.map((location) => {
            return {
                relationId: location.relationId,
                registerId: -1,
                registerType: 'LOCATION',
                type: 'LOCATION',
                name: location.name,
                address: formatAddress(location),
            };
        });

        const groupAssignments = [
            ...responses?.[0],
            ...locationsAsGroupAssignments,
        ];
        return groupAssignments;
    }
);

export const fetchGroupEmptyAssignments = createAsyncThunk(
    'get:ui/groups/emptyAssignments',
    async (params: {
        queryParams: IGetGroupEmptyAssignmentsParams;
        access: IAssignmentsAccess;
    }) => {
        const { queryParams, access } = params;
        const responses = await Promise.all([
            access.registers
                ? restGetGroupEmptyAssignments({
                      ...queryParams,
                      tags:
                          queryParams.tags === 'LOCATION'
                              ? undefined
                              : queryParams.tags,
                  })
                : Promise.resolve([]),
            access.locations
                ? restGetEmptyLocationGroupAssignments({
                      ...queryParams,
                      name: undefined,
                      search: queryParams.name,
                  })
                : Promise.resolve([]),
        ]);
        const locationsAsGroupAssignments = responses?.[1]?.map((location) => {
            return {
                relationId: location.relationId,
                registerId: -1,
                registerType: 'LOCATION',
                type: 'LOCATION',
                name: location.name,
                address: formatAddress(location),
            };
        });

        const groupAssignments = [
            ...responses?.[0],
            ...locationsAsGroupAssignments,
        ];
        return groupAssignments;
    }
);

const processChunk = async (
    id: string,
    added: IGroupAssignment[],
    removed: IGroupAssignment[],
    fetchCall: (
        id: string,
        assignments: IRestPatchGroupAssignments
    ) => Promise<null>
) => {
    const addedChunked = chunk(added, MAX_ITEM_LIMIT);
    for (let i = 0; i < addedChunked.length; i++) {
        await fetchCall(id, {
            added: addedChunked[i],
            removed: [],
        });
    }
    const removedChunk = chunk(removed, MAX_ITEM_LIMIT);
    for (let i = 0; i < removedChunk.length; i++) {
        await fetchCall(id, {
            added: [],
            removed: removedChunk[i],
        });
    }
    return;
};
const handlePatchLocationsAssginments = async (
    id: string,
    added: IGroupAssignment[],
    removed: IGroupAssignment[]
) => {
    const locationAssignmentsDataChunk = Math.floor(
        (added.length + removed.length) / MAX_ITEM_LIMIT
    );
    if (locationAssignmentsDataChunk >= 1) {
        processChunk(id, added, removed, restPatchLocationGroupAssignments);
    }
    const response = await restPatchLocationGroupAssignments(id, {
        added,
        removed,
    });
    return response;
};

const handlePatchAssignments = async (
    id: string,
    added: IGroupAssignment[],
    removed: IGroupAssignment[]
) => {
    const assignmentsDataChunk = Math.floor(
        (added.length + removed.length) / MAX_ITEM_LIMIT
    );
    if (assignmentsDataChunk >= 1) {
        processChunk(id, added, removed, restPatchGroupAssignments);
    }
    const response = await restPatchGroupAssignments(id, { added, removed });
    return response;
};

export const patchGroupAssignments = createAsyncThunk(
    'patch:ui/groups/assignments',
    async (payload: {
        id: string;
        data: {
            added: IGroupAssignmentCollection;
            removed: IGroupAssignmentCollection;
        };
        access: IAssignmentsAccess;
    }) => {
        const { id, data, access } = payload;

        const responses = await Promise.all([
            access.registers
                ? handlePatchAssignments(
                      id,
                      data.added.withoutAddress,
                      data.removed.withoutAddress
                  )
                : Promise.resolve(),
            access.locations
                ? handlePatchLocationsAssginments(
                      id,
                      data.added.withAddress,
                      data.removed.withAddress
                  )
                : Promise.resolve(),
        ]);
        return responses;
    }
);

const groupsSlice = createSlice({
    name: 'groupsSlice',
    initialState,
    reducers: {
        goToPreviewMode(state) {
            state.mode = 'preview';
        },
        enterCreateGroupMode(state) {
            state.mode = 'create';
            state.selectedGroupId = null;
        },
        enterEditGroupMode(state) {
            state.mode = 'edit';
        },
        resetMode(state) {
            state.mode = null;
        },
        clearEmptyAssignments(state) {
            state.emptyAssigmentsLoading = false;
            state.emptyAssignments = [];
        },
        selectGroup(state, action) {
            state.selectedGroupId = action.payload;
            state.mode = 'preview';
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchGroupAssignments.fulfilled, (state, action) => {
            const sortedAssigments = action.payload.sort((a, b) =>
                sortAlphabetically(a.name, b.name)
            );
            state.assignments = sortedAssigments;
        });
        builder.addCase(fetchGroupEmptyAssignments.pending, (state) => {
            state.emptyAssigmentsLoading = true;
        });
        builder.addCase(
            fetchGroupEmptyAssignments.fulfilled,
            (state, action) => {
                if (
                    action.meta.arg.queryParams.excludedGroupId ===
                    state.selectedGroupId
                ) {
                    state.emptyAssignments = action.payload;
                    state.emptyAssigmentsLoading = false;
                }
            }
        );
        builder.addCase(
            fetchGroupEmptyAssignments.rejected,
            (state, action) => {
                state.emptyAssignments = [];
                state.emptyAssigmentsLoading = false;
            }
        );
    },
});

export const getGroupsMode = (state: TRootState) => state.ui.groupsSlice.mode;

export const getSelectedGroupId = (state: TRootState) =>
    state.ui.groupsSlice.selectedGroupId;

export const getAssignments = (state: TRootState) =>
    state.ui.groupsSlice.assignments;

export const getEmptyAssignments = (state: TRootState) =>
    state.ui.groupsSlice.emptyAssignments;

export const getEmptyAssignmentsLoading = (state: TRootState) =>
    state.ui.groupsSlice.emptyAssigmentsLoading;

export const isGroupsPaneVisible = createSelector(
    [getGroupsMode],
    (mode) => !!mode
);

export const {
    enterCreateGroupMode,
    enterEditGroupMode,
    goToPreviewMode,
    resetMode,
    selectGroup,
    clearEmptyAssignments,
} = groupsSlice.actions;

export default groupsSlice.reducer;
