import { Record } from 'immutable';
import EmployeeAddress from './EmployeeAddress';
import TimeZone from './TimeZone';

class Employee extends Record({
    id: undefined,
    externalId: undefined,
    monitoredId: undefined,
    name: undefined,
    firstName: undefined,
    surname: undefined,
    cards: [],
    address: new EmployeeAddress(),
    email: undefined,
    phoneNumber: undefined,
    mobileNumber: undefined,
    groups: [],
    groupIds: [],
    timeZone: new TimeZone(),
}) {
    static fromJson(options) {
        return new Employee({
            id: options.id,
            externalId: options.externalId,
            monitoredId: options.monitoredId,
            name: options.name || '',
            firstName: options.firstName || '',
            surname: options.surname || '',
            cards: options.cards,
            address: EmployeeAddress.fromJson(options.address),
            email: options.contact ? options.contact.email : undefined,
            phoneNumber: options.contact
                ? options.contact.phoneNumber
                : undefined,
            mobileNumber: options.contact
                ? options.contact.mobileNumber
                : undefined,
            groups: options.groups,
            groupIds: options.groupIds,
            timeZone: new TimeZone(options.timeZone),
        });
    }

    toJson() {
        let contact = undefined;
        if (this.email || this.phoneNumber || this.mobileNumber) {
            contact = {
                email: this.email,
                phoneNumber: this.phoneNumber,
                mobileNumber: this.mobileNumber,
            };
        }

        return {
            id: this.id,
            externalId: this.externalId,
            monitoredId: this.monitoredId,
            name: this.name,
            firstName: this.firstName,
            surname: this.surname,
            cards: this.cards,
            contact,
            group: this.group,
            groups: this.groups,
            groupIds: this.groupIds,
            address: this.address.toJson(),
            timeZone: this.timeZone,
        };
    }
}

export default Employee;
