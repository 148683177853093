import { default as Divider } from '@material-ui/core/Divider';
import { default as Paper } from '@material-ui/core/Paper';
import React, { Component, ComponentType } from 'react';
import { compose } from 'redux';
import PaneHeader from '../../../../components/PaneHeader';
import { Connectable, TConnectableProps } from './Connectable.hoc';
import { Themable, TThemableProps } from './Themable.hoc';

import TranslationHelper from '../../../../helpers/TranslationHelper';
import { fetchChartEvents } from '../../../../services/discovery/fetchChartEvents';

import EventChart from '../../../../components/EventChart';

interface IOwnProps {
    layout: string;
}

type TProps = IOwnProps & TThemableProps & TConnectableProps;

class ChartPane extends Component<TProps> {
    public state = {
        allEvents: [],
        filteredEvents: [],
    };

    public componentDidMount() {
        if (this.props.chartPane !== null) {
            fetchChartEvents(this.props.chartPane.params.allEvents).then(
                (data) =>
                    this.setState({
                        allEvents: data.values,
                    })
            );
            fetchChartEvents(this.props.chartPane.params.filteredEvents).then(
                (data) =>
                    this.setState({
                        filteredEvents: data.values,
                    })
            );
        }
    }

    public handleClearClick = () => {
        this.props.resetLevel(this.props.creatorLevel);
    };

    public render() {
        const { classes } = this.props;

        return (
            <Paper className={classes.pane}>
                <div className={classes.wrapper}>
                    <PaneHeader
                        title={TranslationHelper.translate('Chart')}
                        onCloseClick={this.handleClearClick}
                    />
                    <Divider />
                    {this.state.allEvents && this.state.filteredEvents && (
                        <EventChart
                            chartId="tank-chart"
                            allData={this.state.allEvents}
                            filteredData={this.state.filteredEvents}
                            sortedAscending={true}
                        />
                    )}
                </div>
            </Paper>
        );
    }
}

export default compose(
    Themable,
    Connectable
)(ChartPane) as ComponentType<IOwnProps>;
