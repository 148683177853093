import React, { ReactNode, useEffect } from 'react';

import { ISourceSet, sourceSetIds } from '../../../../state/types';

import {
    useGridCreatorLevel,
    useGridSourceSet,
    useIsAnalyticsModeEnabled,
    useIsCalibrationDataChartPaneVisible,
    useIsChartPaneVisible,
    useIsDataGridPaneVisible,
    useIsDetectionChartPaneVisible,
    useIsGridFullscreen,
    usePane,
    usePreviewPaneId,
    useSnapshotActionsToTrigger,
    useTimeLocked,
} from '../../selectors/index.hooks';
import CalibrationPane from '../CalibrationPane';
import ChartPane from '../ChartPane';
import DataGridPane from '../DataGridPane';
import RoutesLeftHeader from '../DataGridPane/components/RoutesLeftHeader';
import TasksLeftHeader from '../DataGridPane/components/TasksLeftHeader';
import TasksPreviewLeftHeader from '../DataGridPane/components/TasksPreviewLeftHeader';
import DetectionPane from '../DetectionPane';
import { useAppDispatch } from '../../../../hooks';
import { selectObject } from '../../../../state/_actions';
import { filterByDate } from '../../../../helpers/filterObject';
import { setActionsToTrigger } from '../../../../state/ui/discovery/snapshotting';

interface IOwnProps {
    layoutClassName: string;
    isBottomPaneVisible: boolean;
}

export interface IDataGridCustomComponents {
    leftHeader?: (
        sourceSet: ISourceSet | null,
        searchPhrase: string,
        onChange: (phrase: string) => void
    ) => ReactNode;
}
type TStyling = void | {
    predicate: () => boolean;
    style: object;
};

const DiscoveryGrid = ({ layoutClassName, isBottomPaneVisible }: IOwnProps) => {
    const dispatch = useAppDispatch();
    const sourceSet = useGridSourceSet();
    const isAnalyticsEnabled = useIsAnalyticsModeEnabled();
    const timeLocked = useTimeLocked();
    const creatorLevel = useGridCreatorLevel();
    const selectedSourceSetElementId = usePreviewPaneId();
    const pane = usePane();
    const isChartPaneVisible = useIsChartPaneVisible();
    const isDataGridPaneVisible = useIsDataGridPaneVisible();
    const isDetectionChartPaneVisible = useIsDetectionChartPaneVisible();
    const isCalibrationChartPaneVisible =
        useIsCalibrationDataChartPaneVisible();
    const isDataGridFullscreen = useIsGridFullscreen();
    const actionsToTrigger = useSnapshotActionsToTrigger();

    const showEventTriggerAction = actionsToTrigger?.filter(
        (action) => action.type === 'showEvent'
    )[0];
    const isGridFullscreen = isDataGridFullscreen || isAnalyticsEnabled;
    const handleTriggerAction = () => {
        if (sourceSet && showEventTriggerAction?.date) {
            dispatch(setActionsToTrigger(undefined, creatorLevel));

            const eventId =
                filterByDate(
                    sourceSet.entities,
                    showEventTriggerAction.date,
                    'updatedAt'
                )?.id || '';

            dispatch(
                selectObject(
                    sourceSet,
                    {
                        id: eventId,
                        _meta: {
                            actions: {
                                preview: {
                                    params: { id: eventId },
                                    api: '',
                                },
                            },
                        },
                    },
                    creatorLevel,
                    false
                )
            );
        }
    };
    useEffect(() => {
        handleTriggerAction();
    }, [showEventTriggerAction, sourceSet]);

    const getCustomComponents = (): IDataGridCustomComponents => {
        switch (sourceSet?.definitionId) {
            case sourceSetIds.tasks:
                return {
                    leftHeader: (
                        sourceSet: ISourceSet | null,
                        searchPhrase: string,
                        onChange: (phrase: string) => void
                    ) => (
                        <TasksLeftHeader
                            sourceSet={sourceSet}
                            filtering={{
                                searchPhrase,
                                onChange,
                            }}
                        />
                    ),
                };
            case sourceSetIds.tasksPreview:
                return {
                    leftHeader: (
                        sourceSet: ISourceSet | null,
                        searchPhrase: string,
                        onChange: (phrase: string) => void
                    ) => (
                        <TasksPreviewLeftHeader
                            sourceSet={sourceSet}
                            filtering={{
                                searchPhrase,
                                onChange,
                            }}
                        />
                    ),
                };
            case sourceSetIds.routes:
                return {
                    leftHeader: (
                        sourceSet: ISourceSet | null,
                        searchPhrase: string,
                        onChange: (phrase: string) => void
                    ) => (
                        <RoutesLeftHeader
                            sourceSet={sourceSet}
                            filtering={{
                                searchPhrase,
                                onChange,
                            }}
                        />
                    ),
                };
            default:
                return {};
        }
    };
    const getBottomPaneStyling = (): TStyling => {
        const isPreviewPaneVisible = pane !== null;

        return [
            {
                predicate: () =>
                    !isPreviewPaneVisible &&
                    isBottomPaneVisible &&
                    !isGridFullscreen,
                style: {
                    position: 'absolute',
                    top: '50%',
                    right: 0,
                    bottom: 0,
                    left: 0,
                },
            },
            {
                predicate: () =>
                    isPreviewPaneVisible &&
                    isBottomPaneVisible &&
                    !isGridFullscreen,
                style: {
                    position: 'absolute',
                    top: '50%',
                    right: '430px',
                    bottom: 0,
                    left: 0,
                },
            },
            {
                predicate: () => isBottomPaneVisible && isGridFullscreen,
                style: {
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                },
            },
        ].find(({ predicate }) => predicate());
    };

    const bottomPaneStyling = getBottomPaneStyling();

    return (
        <>
            {bottomPaneStyling && (
                <div style={bottomPaneStyling.style}>
                    {isDataGridPaneVisible && (
                        <DataGridPane
                            layout={layoutClassName}
                            isAnalyticsEnabled={isAnalyticsEnabled}
                            sourceSet={sourceSet}
                            timeLocked={timeLocked}
                            creatorLevel={creatorLevel}
                            selectedSourceSetElementId={
                                selectedSourceSetElementId
                            }
                            customComponents={getCustomComponents()}
                        />
                    )}
                    {isChartPaneVisible && (
                        <ChartPane layout={layoutClassName} />
                    )}
                    {isCalibrationChartPaneVisible && <CalibrationPane />}
                    {isDetectionChartPaneVisible && <DetectionPane />}
                </div>
            )}
        </>
    );
};

export default DiscoveryGrid;
