import React, { useMemo } from 'react';

import {
    CartesianGrid,
    Line,
    LineChart,
    ReferenceLine,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';

import { ISourceSetModel } from '../../../../state/ui/discovery/types';
import { IAttribute, ISourceSet } from '../../../../state/types';
import {
    handleChartAttributeUpdate,
    selectObject,
} from '../../../../state/_actions';
import { useUserSettings } from '../../../../state/user/index.hooks';

import {
    useActiveChartAttributes,
    useActiveChartAttributesColors,
    useTimeLocked,
} from '../../selectors/index.hooks';

import valueFormatters from '../_utils/valueFormatters';

import TranslationHelper from '../../../../helpers/TranslationHelper';

import { useAppDispatch } from '../../../../hooks';

import { useStyles } from './Themable.hooks';
import { ColorPool } from '../../../../helpers/ColorPool';

interface IOwnProps {
    selectedSourceSetElementId?: string | null;
    sourceSet: ISourceSet;
    creatorLevel: number;
    sourceSetModel: ISourceSetModel | null;
}

const SourceSetGridChart = ({
    creatorLevel,
    sourceSet,
    sourceSetModel,
    selectedSourceSetElementId,
}: IOwnProps) => {
    const timeLocked = useTimeLocked();
    const activeAttributes = useActiveChartAttributes();
    const activeAttributesColors = useActiveChartAttributesColors();
    const userSettings = useUserSettings();

    const dispatch = useAppDispatch();

    const classes = useStyles();

    const attributes = useMemo(
        () =>
            sourceSet.attributes.filter(
                (attribute) => attribute.type === 'number'
            ),
        [sourceSet]
    );
    const defaultColor = ColorPool.getDefaultColor().color;
    const attributesToShow = useMemo(
        () => attributes.filter((attribute) => activeAttributes[attribute.id]),
        [attributes, activeAttributes]
    );
    const data = useMemo(
        () =>
            sourceSet.entities.map((entity) =>
                attributes.reduce(
                    (result, next) => {
                        result[next.id] =
                            sourceSetModel === null ||
                            (sourceSetModel.sourceSetElementIds &&
                                sourceSetModel.sourceSetElementIds.has(
                                    entity.id
                                ))
                                ? entity[next.id]
                                : null;
                        result['line---' + next.id] = entity[next.id];
                        return result;
                    },
                    {
                        ...entity,
                    }
                )
            ),
        [sourceSet, sourceSetModel]
    );

    const selectedElement = data.find(
        (element) => element.id === selectedSourceSetElementId
    );

    const selectedElementX =
        selectedElement &&
        (selectedElement.updatedAt || selectedElement.startDate);
    const makeHandleAttributeClick = (attributeId: string) => () => {
        dispatch(handleChartAttributeUpdate(attributeId));
    };

    const handleChartClick = (data: any) => {
        const activePaylod = data?.activePayload?.[0];
        if (!activePaylod.payload || !activePaylod.value) {
            return;
        }

        dispatch(
            selectObject(
                sourceSet,
                activePaylod.payload,
                creatorLevel,
                timeLocked
            )
        );
    };

    const getAttribute = (name: string) =>
        attributes.find((attribute) => attribute.id === name) as IAttribute;
    return (
        <div className={classes.container}>
            <div className={classes.paramSelectorWrapper}>
                <span className={classes.paramSelectorHeader}>
                    {TranslationHelper.translate('Select parameters')}:
                </span>
                <ul className={classes.paramList}>
                    {attributes.map((attribute) => (
                        <li
                            key={attribute.id}
                            onClick={makeHandleAttributeClick(attribute.id)}
                            className={classes.paramSelectorItem}
                            style={{
                                borderBottomColor:
                                    activeAttributesColors[attribute.id]
                                        ?.color ?? defaultColor,
                            }}
                        >
                            <input
                                type="checkbox"
                                checked={
                                    activeAttributes[attribute.id] || false
                                }
                            />{' '}
                            {TranslationHelper.translate(
                                attribute.label,
                                attribute.translate
                            )}
                        </li>
                    ))}
                </ul>
            </div>
            <div className={classes.chart}>
                <ResponsiveContainer height="100%" width="100%">
                    <LineChart
                        data={data}
                        margin={{ top: 5, right: 5, left: 5, bottom: 5 }}
                        onClick={handleChartClick}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                            dataKey="updatedAt"
                            minTickGap={10}
                            tickFormatter={(value) =>
                                valueFormatters.date({
                                    value,
                                    format: userSettings.dateFormatWithoutSeconds,
                                })
                            }
                        />

                        <Tooltip
                            labelFormatter={(value) =>
                                valueFormatters.date({
                                    value: value as string,
                                    format: userSettings.shortDateFormat,
                                })
                            }
                            content={(props: any) => {
                                return (
                                    <div className={classes.tooltip}>
                                        <p className={classes.tooltipDate}>
                                            {valueFormatters.date({
                                                value: props.label,
                                                format: userSettings.dateFormat,
                                            })}
                                        </p>
                                        {props.payload &&
                                            props.payload
                                                .filter(
                                                    (entry: any) =>
                                                        entry.dataKey.indexOf(
                                                            'line---'
                                                        ) !== 0
                                                )
                                                .map((entry: any) => {
                                                    const attribute =
                                                        getAttribute(
                                                            entry.name
                                                        );
                                                    return (
                                                        <p
                                                            key={entry.name}
                                                            className={
                                                                classes.tooltipEntry
                                                            }
                                                        >
                                                            <span
                                                                style={{
                                                                    color:
                                                                        activeAttributesColors[
                                                                            attribute
                                                                                .id
                                                                        ]
                                                                            ?.color ??
                                                                        defaultColor,
                                                                }}
                                                            >
                                                                {TranslationHelper.translate(
                                                                    attribute.label,
                                                                    attribute.translate
                                                                )}
                                                            </span>
                                                            :{' '}
                                                            {attribute.formatting
                                                                ? valueFormatters.customFormatting(
                                                                      {
                                                                          value: entry.value,
                                                                          formatting:
                                                                              attribute.formatting,
                                                                          type: attribute.type,
                                                                          dateFormat:
                                                                              userSettings.shortDateFormat,
                                                                      }
                                                                  )
                                                                : entry.value}
                                                        </p>
                                                    );
                                                })}
                                    </div>
                                );
                            }}
                        />
                        {selectedElement && attributesToShow.length > 0 && (
                            <ReferenceLine
                                x={selectedElementX}
                                yAxisId="left"
                                stroke="black"
                                label={TranslationHelper.translate(
                                    'Selected event'
                                )}
                            />
                        )}

                        <YAxis yAxisId={'left'} hide={true} />

                        {attributesToShow.map((attribute, index) => {
                            return (
                                <YAxis
                                    yAxisId={attribute.id}
                                    key={`y-${index}`}
                                    dataKey={`line---${attribute.id}`}
                                    hide={true}
                                />
                            );
                        })}

                        {attributesToShow.map((attribute, index) => {
                            const color =
                                activeAttributesColors[attribute.id]?.color ??
                                defaultColor;
                            return (
                                <Line
                                    key={index}
                                    type="monotone"
                                    yAxisId={attribute.id}
                                    dataKey={attribute.id}
                                    stroke="none"
                                    legendType="circle"
                                    dot={{
                                        stroke: color,
                                        strokeWidth: 1,
                                    }}
                                    activeDot={{
                                        r: 8,
                                        stroke: 'black',
                                        fill: color,
                                    }}
                                    isAnimationActive={false}
                                />
                            );
                        })}
                        {attributesToShow.map((attribute, index) => {
                            return (
                                <Line
                                    key={'line-' + index}
                                    yAxisId={attribute.id}
                                    type="monotone"
                                    legendType="none"
                                    dataKey={'line---' + attribute.id}
                                    stroke={
                                        activeAttributesColors[attribute.id]
                                            ?.color ?? defaultColor
                                    }
                                    dot={false}
                                    activeDot={false}
                                    isAnimationActive={false}
                                />
                            );
                        })}
                    </LineChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
};

export default SourceSetGridChart;
