import { connect } from 'react-redux';
import { fetchSourceSet, resetLevel } from '../../../../state/_actions';
import { TRootState } from '../../../../store';
import { getChartCreatorLevel, getDiscoveryUIChartPane } from '../../selectors';

const mapStateToProps = (state: TRootState) => ({
    creatorLevel: getChartCreatorLevel(state),
    chartPane: getDiscoveryUIChartPane(state),
    isPreviewPaneVisible: state.ui.discovery.visibility.previewPane,
});

const mapDispatchToProps = { resetLevel, fetchSourceSet };

type TStateProps = ReturnType<typeof mapStateToProps>;
type TDispatchProps = typeof mapDispatchToProps;

export type TConnectableProps = TStateProps & TDispatchProps;

export const Connectable = connect(mapStateToProps, mapDispatchToProps);
