import React from 'react';
import { useDispatch } from 'react-redux';
import {
    useActiveAttributes,
    useDateFormat,
    useEvents,
    useLoading,
    useOnDemandData,
} from './Connectable.hooks';
import { useStyles } from './Themable.hooks';

import {
    CartesianGrid,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';

import ChartParamsList from '../../../../../../components/ChartParamsList';
import TimeFormatter from '../../../../../../helpers/TimeFormatter';
import TranslationHelper from '../../../../../../helpers/TranslationHelper';
import { updateDetectionChartAttribute } from '../../../../../../state/ui/discovery/snapshotting';
import { LinearProgress } from '@material-ui/core';

const dataSets = [
    {
        id: 'raw',
        name: TranslationHelper.translate('Source data'),
        dataKey: 'raw',
        color: '#3949AB',
        type: 'line',
    },
    {
        id: 'processed',
        name: TranslationHelper.translate('Processed data'),
        dataKey: 'processed',
        color: '#e53935',
        type: 'line',
    },
];

const DetectionChart = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const activeAttributes = useActiveAttributes();
    const dateFormat = useDateFormat();
    const data = useEvents();
    const onDemandData = useOnDemandData();
    const loading = useLoading();

    if (!data) {
        return (
            <div className={classes.container}>
                <div className={classes.placeholder}>
                    <p>
                        {TranslationHelper.translate(
                            'Choose a date for archive events'
                        )}
                    </p>
                </div>
            </div>
        );
    }

    const preparedData = data.map((el) => {
        const rawValue = Object.values(el.params).find(
            (e) => e.name === 'FUELLVL'
        )?.value;
        const processedValue = onDemandData?.find((e) => e.d === el.date)?.v;

        return {
            name: el.date,
            raw: typeof rawValue === 'number' ? rawValue : null,
            processed:
                typeof processedValue === 'number' ? processedValue : null,
        };
    });

    const handleAttributeChange = (name: string) => {
        dispatch(updateDetectionChartAttribute(name));
    };

    const getFormattedLabel = (value: any) =>
        TimeFormatter.dateToString(value, dateFormat);

    return (
        <div className={classes.container}>
            <ChartParamsList
                handleAttributeChange={handleAttributeChange}
                dataSets={dataSets}
                activeAttributes={activeAttributes}
            />
            <div className={classes.chart}>
                {loading && <LinearProgress />}
                <ResponsiveContainer height="100%" width="100%">
                    <LineChart
                        margin={{
                            top: 40,
                            right: 20,
                            bottom: 10,
                            left: 10,
                        }}
                        data={preparedData}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                            dataKey={'name'}
                            tickFormatter={getFormattedLabel}
                        />
                        <YAxis />
                        {dataSets
                            .filter(
                                (dataSet) => activeAttributes[dataSet.dataKey]
                            )
                            .map((dataSet) => (
                                <Line
                                    name={dataSet.name}
                                    dataKey={dataSet.dataKey}
                                    stroke={dataSet.color}
                                    dot={false}
                                    key={dataSet.id}
                                    isAnimationActive={false}
                                    connectNulls={true}
                                />
                            ))}
                        <Tooltip />
                    </LineChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
};

export default DetectionChart;
