import React, { useEffect, useRef, useState } from 'react';

import { LinearProgress, useMediaQuery } from '@material-ui/core';

import 'ol/ol.css';
import Feature from 'ol/Feature';
import Map from 'ol/Map';
import TileLayer from 'ol/layer/Tile';
import View from 'ol/View';
import { Vector as VectorSource } from 'ol/source';
import { OSM } from 'ol/source';
import { unByKey } from 'ol/Observable';
import { EventsKey } from 'ol/events';
import { MapBrowserEvent } from 'ol';
import { Geometry } from 'ol/geom';

import { MOBILE } from '../../../../constants/dictionaries/ScreenSizeConst';

import {
    fitMap,
    getControls,
    LARGE_MAP_PADDING,
} from '../../../discovery/components/DiscoveryMap/_utils';
import { makePoint } from '../../../discovery/components/DiscoveryMap/_utils/features';
//@ts-ignore
import Toggle from 'ol-ext/control/Toggle';

import PaneHeader from '../../../../components/PaneHeader';

import { createLayer, getFeatureAtPixel } from './utils/utils';
import { useStyles } from '../../Themable.hooks';
import TranslationHelper from '../../../../helpers/TranslationHelper';

import {
    useClientAndLocationsSourceSetWithFilter,
    useClientsAndLocationsStatus,
    useMainMapVisible,
    useMap,
} from '../../../../state/ui/customerService/clientsAndLocations/index.hooks';
import { ISourceSet } from '../../../../state/types';
import {
    CLIENTS_AND_LOCATIONS_GRID_ID,
    setMap,
} from '../../../../state/ui/customerService/clientsAndLocations';
//@ts-ignore
import LayerSwitcher from 'ol-ext/control/LayerSwitcher';
import { useToolkitDispatch } from '../../../../hooks';

import {
    IBottomGridProps,
    ISelectFeaturesOnMapParams,
    TPointsLayer,
    TVectorGeometrySource,
} from '../../types';
import { STYLE } from './utils/style';
import { handleMultiSelect, handleSingleSelect } from './utils/handlers';
import { disposeMap } from './utils/disposeMap';

interface IOwnProps {
    handleSelectFeatures: (params: ISelectFeaturesOnMapParams) => void;
    selectedItems: { [key: string]: string[] };
    locationsSource: TVectorGeometrySource;
    setSource: (source: VectorSource<Geometry>) => void;
    bottomGrid: IBottomGridProps;
}

const zoomTreshold = 10;
const BOTTOM_GRID_CLUSTER_COLOR = '#0b592f';
const CscMap = ({
    handleSelectFeatures,
    selectedItems,
    locationsSource,
    setSource,
    bottomGrid,
}: IOwnProps) => {
    const classes = useStyles();
    const mapVisible = useMainMapVisible();
    const toolkitDispatch = useToolkitDispatch();
    const map = useMap();

    const mapRef = useRef(null);
    const [clickListener, setClickListener] = useState<EventsKey | EventsKey[]>(
        []
    );
    const [pointsLayer, setPointsLayer] = useState<TPointsLayer>(null);
    const [pointsLayerBottom, setPointsLayerBottom] =
        useState<TPointsLayer>(null);
    const [clustering, setClustering] = useState(true);
    const locationsSourceSet: ISourceSet | null =
        useClientAndLocationsSourceSetWithFilter();

    const isMobile = useMediaQuery(MOBILE);
    const controls = getControls(isMobile);
    const status = useClientsAndLocationsStatus();
    useEffect(() => {
        //add click listeners
        if (!map || !locationsSourceSet) {
            return;
        }

        unByKey(clickListener);
        const click = map.on('click', (e) => handleMapClick(e, selectedItems));
        setClickListener(click);
    }, [map, locationsSourceSet, locationsSource, selectedItems]);

    useEffect(() => {
        //create map
        const map = new Map({
            target: mapRef.current as unknown as HTMLDivElement,
            layers: [
                new TileLayer({
                    source: new OSM(),
                    // @ts-ignore
                    displayInLayerSwitcher: false,
                }),
            ],
            controls,
            view: new View({
                center: makePoint(18.6466, 54.352),
                zoom: 4,
                minZoom: 4,
            }),
        });
        toolkitDispatch(setMap(map));
        const layerSwitcher = new LayerSwitcher({});
        layerSwitcher.element.title = TranslationHelper.translate('Layers');

        map.addControl(layerSwitcher);
        map.getView().on('change:resolution', () => {
            const zoomLevel = map.getView().getZoom();
            if (zoomLevel && zoomLevel >= zoomTreshold) {
                setClustering(false);
                toggle.setActive(false);
            }
        });
        const toggle = new Toggle({
            className: 'cluster-toggle',
            onToggle: (newValue: boolean) => {
                var view = map.getView();

                view.setZoom(zoomTreshold - 1);
                setClustering(newValue);
            },
            title: TranslationHelper.translate('Clustering'),
            active: clustering,
        });
        map.addControl(toggle);
        return () => {
            disposeMap(map, clickListener);
        };
    }, []);

    useEffect(() => {
        if (!bottomGrid.id) {
            return;
        }
        const items = selectedItems[bottomGrid.id];

        return createLayer({
            map,
            sourceSet: bottomGrid.sourceSet,
            style: bottomGrid.style,
            setLayer: setPointsLayerBottom,
            pointsLayer: pointsLayerBottom,
            setSource: bottomGrid.setSource,
            clustering,
            items,
            clusterColor: BOTTOM_GRID_CLUSTER_COLOR,
        });
    }, [bottomGrid.sourceSet, clustering]);

    useEffect(() => {
        const items = selectedItems[CLIENTS_AND_LOCATIONS_GRID_ID];
        return createLayer({
            map,
            sourceSet: locationsSourceSet,
            style: STYLE,
            setLayer: setPointsLayer,
            pointsLayer: pointsLayer,
            setSource,
            clustering,
            items,
        });
    }, [locationsSourceSet, clustering]);

    useEffect(() => {
        //dispose map after change in selectedRoutes - to properly refresh features
        if (map && pointsLayer) {
            pointsLayer.dispose();
            map.removeLayer(pointsLayer);
        }
    }, [locationsSourceSet]);
    useEffect(() => {
        //dispose map after change in selectedRoutes - to properly refresh features
        if (map && pointsLayerBottom) {
            pointsLayerBottom.dispose();
            map.removeLayer(pointsLayerBottom);
        }
    }, [bottomGrid.sourceSet]);
    const getSource = (feature: Feature<Geometry>) => {
        return feature.get('sourceSetId') === CLIENTS_AND_LOCATIONS_GRID_ID
            ? locationsSource
            : bottomGrid.source;
    };
    const handleMapClick = (
        event: MapBrowserEvent<MouseEvent>,
        items: { [key: string]: string[] }
    ) => {
        if (!map) {
            return;
        }
        const feature = getFeatureAtPixel(map, event.pixel);
        if (!feature) {
            return;
        }
        const geometry = feature.getGeometry();
        // Get the extent of the geometry (bounding box)
        const extent = geometry?.getExtent();
        if (extent) {
            const currentZoom = map.getView().getZoom();
            const zoom =
                currentZoom && currentZoom > zoomTreshold
                    ? currentZoom
                    : zoomTreshold;
            fitMap(map, extent, LARGE_MAP_PADDING, zoom);
        }

        if (clustering) {
            setClustering(false);
        }
        const source = getSource(feature);

        if (event.originalEvent.ctrlKey) {
            // Select with ctrl to select another item, without unselecting
            handleMultiSelect(feature, handleSelectFeatures, items);
        } else {
            // Select without ctrl to unselect all other items

            handleSingleSelect(feature, source, handleSelectFeatures);
        }
    };

    return (
        <div style={{ display: mapVisible ? 'flex' : 'none' }}>
            <div className={classes.mapContainer}>
                <div className={classes.mapPane}>
                    <PaneHeader title={TranslationHelper.translate('Map')} />
                </div>
                {status === 'loading' && <LinearProgress />}
                <div className={classes.smallMap}>
                    <div
                        id="cscmap"
                        ref={mapRef}
                        style={{ width: '100%' }}
                        className={classes.map}
                    ></div>
                </div>
            </div>
        </div>
    );
};

export default CscMap;
